import parse from 'html-react-parser';
import React from 'react';
import '../../../resources/css/hotel-facilities.scss';
import { useDisclosure } from '@nextui-org/react';
import { hotelStore } from '../../../store/HotelStore';
import ModalTemplate from '../../../components/ui/ModalTemplate';
import AboutAccommodationModal from './AboutAccommodationModal';
import HotelFacilitiesRcol from './HotelFacilitiesRcol';
import moment from 'moment';


type HotelFacilitiesProps = {
  id?: string;
  facilitiesData: {
    description: string;
    propertyName: string;
    hotelFacilities: string[];
    bookingDetails: {
      keyword: string;
      checkInDate: string;
      checkOutDate: string;
      rooms: number;
      adults: number;
      longitude: number;
      latitude: number;
    };
    address: string;
    attractions: string[];
  };
};

const HotelFacilities = ({ id, facilitiesData }: HotelFacilitiesProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const openAboutAccommodationModal = () => onOpen();
  // const openCancellationPolicyModal = () => onOpen();

  const { openAboutAccommodation, closeAboutAccommodation } = hotelStore();

 


  const closeAboutAccommodationModal = () => {
    onClose();
    closeAboutAccommodation();
  };

  const { description, propertyName, hotelFacilities,bookingDetails } = facilitiesData;
  const aboutAccommodationModalData = {
    description,
    propertyName,
  };
  const splitDescription = description ? description.toString().split(/<\/\D>/g) : '';
  const minDescription = `
    ${splitDescription[0] !== undefined ? splitDescription[0] : ''}
    ${splitDescription[1] !== undefined ? splitDescription[1] : ''}
    ${splitDescription[2] !== undefined ? splitDescription[2] : ''}
  `;


  const bookingDates = `${bookingDetails.checkInDate} - ${bookingDetails.checkOutDate}`
  const totalNights = moment(bookingDetails.checkOutDate).diff(moment(bookingDetails.checkInDate), 'days');
  const roomsGuests = ` ${bookingDetails.adults}`;
  // const location = `${facilitiesData.address}`


  return (
    <>
      <section id={id} className="container  mt-20">
        <div className="row justify-content-center">
          {/* left col */}
          <div className="col-11 ">
            <div className="relative mb-2.5 font-semibold text-xl">About your stay</div>
            <div className="flex flex-row gap-2 text-base font-medium mb-6">
              
              <span>{bookingDates}</span>
              <span>|</span>
              <span>{totalNights} {totalNights === 1 ? 'night' : 'nights'}</span>
              <span>|</span>
              <span>{roomsGuests} room</span>
              {/* <span>{location}</span> */}
            </div>

            <div className="flex flex-column gap-4 border-bottom pb-10">
              <div className="">{parse(minDescription.replaceAll('<p>', '<p className="[margin-block-start:0] [margin-bottom:8px] font-footnote fs-6 font-regular">'))}</div>
              <a
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  openAboutAccommodationModal();
                  openAboutAccommodation();
                }}
                href="/#"
                className="font-medium text-base"
              >
                See More
              </a>
            </div>

            {/* rcol */}
            <HotelFacilitiesRcol hotelFacilities={hotelFacilities} />
          </div>
        </div>

        <div className="row justify-content-center"></div>
      </section>

      <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[741px] w-[900px]">
        <AboutAccommodationModal onClose={closeAboutAccommodationModal} aboutAccommodationModalData={aboutAccommodationModalData} />
      </ModalTemplate>
    </>
  );
};

export default HotelFacilities;
