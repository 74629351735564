import React, { ReactElement } from 'react';
import { Button } from '@nextui-org/button';
import { useDisclosure } from '@nextui-org/use-disclosure';
import ModalTemplate from '../../../components/ui/ModalTemplate';
import AllHotelPhotosModal from './AllHotelPhotosModal';
// import { Dialog, DialogTrigger, DialogContent } from '../../../components/ui/dialog';
import ShareHotelModal from './ShareHotelModal';
import { Star } from '../../../resources/svg/StarSvg';
import '../../../resources/css/gallery.scss';
import { AlertBox } from '../../../components/AlertBox';
// import { UserStore } from '../../../store/UserStore';
// import { EmailDialog } from '../../login/EmailDialog';
// import HotelBookmarkModal from './HotelBookmarkModal';

type HotelOverviewProps = {
  id?: string;
  overviewData: {
    images: any;
    propertyName: any;
    facilities: string[];
    bookingDetails: string;
    rating: number;
    address: string;
  };
};

const HotelOverview = ({ id, overviewData }: HotelOverviewProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { images, propertyName, facilities, rating } = overviewData;
  // const { isLoggedIn } = UserStore();

  // const [isImgError, setIsImgError] = React.useState(false);

  const [showShareDialog, setShowShareDialog] = React.useState(false);

  const starRating: ReactElement[] = [];

  for (let index = 0; index < rating; index++) {
    starRating.push(
      <span className="mr-1" key={index}>
        <Star _width={16} _height={16} />
      </span>
    );
  }

  // eslint-disable-next-line
  const [showEmailDialog, setShowEmailDialog] = React.useState(false);
  // const [showFavoriteDialog, setShowFavoriteDialog] = React.useState(false);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-11 flex justify-between">
          <div className="flex flex-column gap-2">
            <b className="relative textColor  font-semibold text-[32px]">{propertyName}</b>
            <div className="flex gap-2.5 align-items-center align-middle text-base">
              <div className="flex flex-row"> {starRating} </div>
              <span className="text-base fw-medium">{rating}-star rating</span>
            </div>
          </div>

          <div>
            <div className="flex flex-row items-start justify-start gap-[1.5rem] text-left text-[1rem] text-text-colors-text-primary font-footnote">
              <Button
                onClick={() => setShowShareDialog(true)}
                className=" d-none p-0 bg-transparent flex flex-row items-center justify-center gap-[0.5rem] textColor font-medium font-footnote leading-[1.31rem]"
              >
                <img className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0" alt="" src="/svg/bxsharealtsvg.svg" />
                <span className="textColor">Share</span>
              </Button>

              <Button onClick={() => setShowEmailDialog(true)} className="p-0 bg-transparent flex flex-row items-center justify-center gap-[0.5rem] font-medium font-footnote  leading-[1.31rem]">
                <img className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0" alt="" src="/svg/bookmark.svg" />
                <span className="textColor">Save</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* {images.length > 0 && isImgError === false ? ( */}
      {images.length > 0 ? (
        <div className="d-flex justify-content-center mt-8">
          <div className="gallery-wrapper">
            <div className="large-pic">{images && <img loading="lazy" alt="" src={images[0] ?? ''} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />}</div>

            <div className="gallery-photo">
              <div className="upper ">
                <div className="w-100 bg-danger upp-img">{images && <img loading="lazy" className="" alt="" src={images[5]} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />}</div>
                <div className="w-100 bg-danger upp-img">{images && <img loading="lazy" className="" alt="" src={images[4]} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />}</div>
              </div>
              <div className="lower ">
                <div className="w-100 bg-success low-img">{images && <img loading="lazy" className="" alt="" src={images[7]} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />}</div>
                <div className="w-100 bg-success low-img cursor-pointer dark" onClick={onOpen}>
                  <div className="position-absolute overlay"></div>
                  <p className="position-absolute">See all Photos</p>
                  {images && <img loading="lazy" className="" alt="" src={images[3]} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="w-[77.25rem] h-[49rem]">
        <AllHotelPhotosModal onClose={onClose} images={images} facilities={facilities} />
      </ModalTemplate>

      {/* Share Hotel Profile Modal */}
      <AlertBox show={showShareDialog} onHide={() => setShowShareDialog((prev) => !prev)} title="Tell your friends about this place!" size="lg">
        <>
          <ShareHotelModal overviewData={overviewData} />
        </>
      </AlertBox>

      {/* Bookmarks */}
      {/* {!isLoggedIn ? (
        <AlertBox show={showEmailDialog} onHide={() => setShowEmailDialog((prev) => !prev)} title="Log-in or Sign up">
          <>
            <EmailDialog
              disableEmailButton={false}
              show={showEmailDialog}
              onHide={() => {
                setShowEmailDialog(false);
              }}
              emailChange={}
              verifyEmail={}
              errorEmail={false}
              isNotValidEmail={false}
              isEmptyEmail={false}
            />
          </>
        </AlertBox>
      ) : (
        <AlertBox show={showFavoriteDialog} onHide={() => setShowFavoriteDialog((prev) => !prev)} title="favorites">
          <></>
        </AlertBox>
      )} */}
    </div>
  );
};

export default HotelOverview;
