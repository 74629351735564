import React from 'react';
import Footer from '../Footer';
import HotelConfirmationProgress2 from './HotelConfirmationProgress2';
import HotelBookingSummary from './HotelBookingSummary';
import { useDisclosure } from '@nextui-org/react';
import { useLocation, useNavigate } from 'react-router';
import ModalTemplate from '../ui/ModalTemplate';
import HotelBookingModal from '../../pages/book/hotels/HotelBookingModal';
import { NavBar } from '../NavBar';
import { HotelCartStore } from '../../store/HotelCartStore';
import { HotelPreBookingHooks } from '../../hooks/HotelPreBookingHooks';
import { GalagoButton } from '../Button';

const HotelPaymentPage = () => {
  const navigate = useNavigate();
  const { preBookingGalago } = HotelPreBookingHooks();

  const { setSelectedHotelRooms } = HotelCartStore();
  const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure();

  const urlData = useLocation();
  const { hotelInfo, selectedRooms, nights, searchCriteria, tboDataResponse, transactionId, hotel } = urlData.state;

  const [disableButton, setDisableButton] = React.useState(true);

  let hotelPreBookRoomsPayload: { [index: string]: any } = [];

  React.useEffect(() => {
    tboDataResponse.map((value: { [index: string]: any }, key: number) => (hotelPreBookRoomsPayload[key] = value.Rooms[0]));

    let hotelPreBookPayload: { [index: string]: any } = {
      transactionId: transactionId,
      provider: 'tbo',
      providerDetails: {
        HotelCode: tboDataResponse[0].HotelCode,
        Currency: tboDataResponse[0].Currency,
        RateConditions: tboDataResponse[0].RateConditions,
        Rooms: hotelPreBookRoomsPayload,
      },
    };

    if (tboDataResponse.length > 0) {
      preBookingGalago(hotelPreBookPayload, setDisableButton);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <NavBar />
      <HotelConfirmationProgress2 />
      <main className="container pt-5">
        <div className="row text-start pb-3">
          <div className="col-4 mx-auto">
            <b className="" style={{ fontSize: '34px' }}>
              Booking Summary
            </b>
            <p className="satoshi-regular" style={{ fontSize: '16px' }}>
              Review your booking before proceeding to payment!
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-5">
            <HotelBookingSummary
              setSelectedHotelRooms={setSelectedHotelRooms}
              navigate={navigate}
              searchCriteria={searchCriteria}
              hotelInfo={hotelInfo}
              selectedRooms={selectedRooms}
              nights={nights}
              facilitiesData={hotelInfo?.facilitiesData}
              preBookingTBOResponse={tboDataResponse}
            />

            <section className="flex flex-row items-start justify-start w-full gap-2">
              <GalagoButton onClick={() => navigate(-1)} label="Back" btn="light" />
              <GalagoButton onClick={onOpen} disabled={disableButton} label="Proceed to payment" btn="primary" />
            </section>
          </div>
        </div>
      </main>
      <div className="p-3"></div>
      <Footer />

      {isOpen && (
        <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[25.63rem] w-[25.63rem]">
          <HotelBookingModal onClose={onClose} urlPayload={urlData.state} hotel={hotel} />
        </ModalTemplate>
      )}
    </>
  );
};

export default HotelPaymentPage;
