// css
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../resources/css/flight-results.scss';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GalagoButton } from '../../../components/Button';
import { FlightDetailsModal } from './FlightDetailsModal';
import { SearchPageHook } from '../../../hooks/SearchPageHook';
import { FlightBookingHooks } from '../../../hooks/FlightBookingHooks';
import { HTTP_CREATED } from '../../../utils/httpResponse.utils';
import { OneWayFlight } from './OneWayFlight';
import { FlightInfoStore } from '../../../store/FlightInfoStore';
import { NavBar } from '../../../components/NavBar';
import { backToTop } from '../../../utils/data.utils';
import Footer from '../../../components/Footer';
import FlightResultsSearchForm from './FlightResultsSearchForm';
import { RoundTripBundle } from './RoundTripBundle';
import { FlightUrlHook } from '../../book/hotels/FlightUrlHook';

export const FlightResults = () => {
  const flightType = window.location.hash.split('?')[0].split('/').pop(); // one-way | round-trip
  const { searchAvailableFlights, conversationId, flights } = SearchPageHook();
  const { flightSearchUrl } = FlightUrlHook();
  const { createFlightTransaction } = FlightBookingHooks();
  const { flightInfo, showFlightInfoModal, setShowReturnFlights, setShowFlightInfoModal, setFlightInfo } = FlightInfoStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // selected flights
  const [selectedFlight, setSelectedFlight] = useState<{ [index: string]: any } | undefined>();

  // button management
  const [isBookFlightButtonDisabled, setIsBookFlightButtonDisabled] = useState(false);

  const getOriginCode = searchParams.get('originCode');
  const getDestinationCode = searchParams.get('destinationCode');
  const getOriginCity = searchParams.get('originCity');
  const getDestinationCity = searchParams.get('destinationCity');
  const getDepartureDate = searchParams.get('departureDate');
  const getReturnDate = searchParams.get('returnDate');
  const getAdults = searchParams.get('adults') ?? 1;
  const getMinors = searchParams.get('minors') ?? 0;
  const getChildren = searchParams.get('children') ?? 0;
  const getInfants = searchParams.get('infants') ?? 0;
  const getCabinClass = searchParams.get('class');

  // one-way and round-trip url switching logic
  const searchType: 'one-way' | 'round-trip' | 'multi-city' = flightType as 'one-way' | 'round-trip' | 'multi-city';

  const { URL, urlPayload } = flightSearchUrl(flightType, searchParams);

  useEffect(() => {
    // search for available flights
    (async () => await searchAvailableFlights(URL, getCabinClass ?? ''))();
    setSelectedFlight(undefined);
    // eslint-disable-next-line
  }, [searchParams, flightType]);

  const passengers = { adults: +getAdults, minors: +getMinors, children: +getChildren, infants: +getInfants };
  const fromCityName = getOriginCity;
  const fromAirportCode = getOriginCode;
  const toCityName = getDestinationCity;
  const toAirportCode = getDestinationCode;
  const departingDate = moment(getDepartureDate).format('DD MMMM YYYY');
  const returnDate = flightType === 'round-trip' ? moment(getReturnDate).format('DD MMMM YYYY') : '';
  const cabinClass = getCabinClass;

  const proceedToPassengerDetailsPage = () => {
    const payload = {
      origin: fromAirportCode,
      destination: toAirportCode,
      adults: passengers.adults,
      children: passengers.children,
      infants: passengers.infants,
      departureDate: moment(departingDate).format('YYYY/MM/DD'),
      returnDate: returnDate !== '' ? moment(returnDate).format('YYYY/MM/DD') : null,
      // cabinClass: cabinClass.charAt(0).toUpperCase() + cabinClass.slice(1),
      cabinClass: cabinClass
        ?.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(' ', '-')
        .toLowerCase(),
      flightType: flightType,
    };

    // disable back and book flight button
    setIsBookFlightButtonDisabled(true);
    // create transaction, transactionId is to be keep, we will carry this id to the next page
    createFlightTransaction(payload).then((response: any) => {
      // enable the back and book flight button
      setIsBookFlightButtonDisabled(false);
      if (response.status === HTTP_CREATED()) {
        const id = response.data.data.transactionId;
        navigate(`/book/flight?id=${id}&${urlPayload}`, {
          state: {
            transactionId: id,
            selectedFlightDetails: {
              flight: selectedFlight,
              fromAirportCode: fromAirportCode,
              toAirportCode: toAirportCode,
              passengers: passengers,
              conversationId: conversationId,
              type: searchType,
              cabin: getCabinClass,
            },
            passengerTypesCount: {
              adults: passengers.adults,
              minors: passengers.minors,
              children: passengers.children,
              infants: passengers.infants,
            },
          },
        });
      }
    });
  };

  return (
    <>
      <NavBar />
      <div className="result_wrapper position-relative" style={{ backgroundColor: '#f8f8f8', paddingBottom: '1em' }}>
        <FlightResultsSearchForm
          setFlightInfo={setFlightInfo}
          searchCriteria={{ passengers, fromCityName, fromAirportCode, toCityName, toAirportCode, departingDate, returnDate, cabinClass }}
          searchType={searchType}
          setShowReturnFlights={setShowReturnFlights}
        />

        {searchType === 'one-way' ? (
          <OneWayFlight
            fromCityName={fromCityName ?? ''}
            fromAirportCode={fromAirportCode ?? ''}
            toCityName={toCityName ?? ''}
            toAirportCode={toAirportCode ?? ''}
            selectedFlight={selectedFlight}
            setSelectedFlight={setSelectedFlight}
            flights={flights}
            searchParams={searchParams}
          />
        ) : null}

        {searchType === 'round-trip' ? (
          <RoundTripBundle
            fromCityName={fromCityName ?? ''}
            fromAirportCode={fromAirportCode ?? ''}
            toCityName={toCityName ?? ''}
            toAirportCode={toAirportCode ?? ''}
            selectedFlight={selectedFlight}
            setSelectedFlight={setSelectedFlight}
            flights={flights}
            searchParams={searchParams}
          />
        ) : null}
        {selectedFlight ? (
          <div className="container mb-4">
            <div className="row justify-content-end gap-1">
              <div className="col-lg-2 col-sm-4">
                <GalagoButton label={'BACK TO HOME'} disabled={isBookFlightButtonDisabled} btn="light" size="sm" onClick={() => navigate('/')} />
              </div>
              <div className="col-lg-2 col-sm-4">
                <GalagoButton
                  label={'BOOK FLIGHT'}
                  btn="primary"
                  size="sm"
                  style={{ width: '100%' }}
                  disabled={isBookFlightButtonDisabled}
                  onClick={() => {
                    proceedToPassengerDetailsPage();
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {Object.keys(flightInfo).length > 0 ? (
          <FlightDetailsModal
            show={showFlightInfoModal}
            onHide={() => {
              setShowFlightInfoModal(false);
              setFlightInfo({});
            }}
            btnClick={(e) => {
              setShowFlightInfoModal(false);
              setFlightInfo({});
              setSelectedFlight(flightInfo);
              backToTop();
            }}
            flightInfo={flightInfo}
          />
        ) : null}
        {/* <ErrorGalagoPage title={foundErrorTitle} content={foundErrorContent} buttonClick={(): void => {}} show={noResultsFoundError} /> */}
      </div>
      <Footer />
    </>
  );
};
