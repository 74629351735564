import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Footer from '../../../components/Footer';
import HotelAvailableRooms from './HotelAvailableRooms';
import HotelFacilities from './HotelFacilities';
import HotelLocation from './HotelLocation';
import HotelOverview from './HotelOverview';
import HotelPolicies from './HotelPolicies';
import { NavBar } from '../../../components/NavBar';
import { HotelCartStore } from '../../../store/HotelCartStore';
// import { useSearchParams } from 'react-router-dom';
// import { set } from 'lodash';

const HotelProfile = () => {
  const urlData = useLocation();
  const { setSelectedHotelRooms } = HotelCartStore();
  // reset selected rooms

  useEffect(() => {
    setSelectedHotelRooms({}, true);
    // eslint-disable-next-line
  }, []);

  const { searchCriteria } = urlData.state.payload;

  const { hotel } = urlData.state?.payload;
  const bookingDetails: string = urlData.state?.payload.bookingDetails;
  const nights: number = urlData.state?.payload.nights;

  console.log('adasd',searchCriteria);

  const overviewData = {
    images: hotel?.Images,
    propertyName: hotel?.HotelName,
    facilities: hotel?.HotelFacilities,
    bookingDetails: bookingDetails,
    rating: hotel?.HotelRating,
    address: hotel?.Address,
  };

  const facilitiesData = {
    description: hotel?.Description,
    propertyName: hotel?.HotelName,
    hotelFacilities: hotel?.HotelFacilities,
    address: hotel.Address,
    attractions: hotel.Attractions,
    bookingDetails: searchCriteria,
  };


  const availableRoomsData = {
    rooms: hotel?.Rooms,
    nights,
  };

  const policiesData = {
    checkInTime: hotel?.CheckInTime,
    checkOutTime: hotel?.CheckOutTime,
  };

  const locationData = {
    address: hotel?.Address,
    coordinates: hotel.Map,
  };

  const { Currency } = hotel;


  const [scrollPosition, setscrollPosition] = useState(0);
  const navref = useRef<HTMLDivElement>(null);
  const handleNavScroll = () => {
    if (navref.current) {
      const currentNavPosition = navref.current.getBoundingClientRect().top;
      setscrollPosition(currentNavPosition);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleNavScroll);
    return () => {
      window.removeEventListener('scroll', handleNavScroll);
    };
    // eslint-disable-next-line
  }, []);

  const [activeLink, setActiveLink] = useState<string | null>(null);

  const scrollToSection = (id: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const element = document.getElementById(id);

    if (element) {
      const offset = 108;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
    setActiveLink(id);
  };

  return (
    <div>
      <NavBar />

      <main className="relative flex flex-col items-center w-full justify-stretch bg-white-white-100">
        {/* to offset fixed navbars above */}
        <div className="relative pt-4" />
        <div className="relative h-full pb-28 flex flex-col items-start justify-start text-left text-[1.5rem] textColor font-footnote">
          <HotelOverview id="hotelOverview" overviewData={overviewData} />
          <HotelFacilities id="facilities" facilitiesData={facilitiesData} />

          <div className="relative flex flex-column" ref={navref}>
            <nav className="mb-10 flex flex-row items-center w-full justify-center textColor generalsans-medium text-base sticky top-0 z-50 bg-white">
              <div
                className="flex flex-row w-[90%] py-3.5 justify-center gap-10"
                style={{ borderTop: scrollPosition > 0 ? '1px solid #E7EAEB' : 'none', borderBottom: scrollPosition > 0 ? '1px solid #E7EAEB' : '4px solid rgba(208, 240, 239, 0.5)' }}
              >
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('available-rooms', e);
                  }}
                  className="py-2 px-6 no-underline cursor-pointer"
                  style={{ color: activeLink === 'available-rooms' ? '#016E7F' : '#333' }}
                >
                  Available Rooms
                </a>
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('location', e);
                  }}
                  className=" py-2 px-6 no-underline cursor-pointer"
                  style={{ color: activeLink === 'location' ? '#016E7F' : '#333' }}
                >
                  Location
                </a>
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('policies', e);
                  }}
                  className=" py-2 px-6 no-underline cursor-pointer"
                  style={{ color: activeLink === 'policies' ? '#016E7F' : '#333' }}
                >
                  Property Policies
                </a>
              </div>
            </nav>

            <div id="available-rooms">
              <HotelAvailableRooms hotel={hotel} searchCriteria={searchCriteria} Currency={Currency} hotelInfo={overviewData} facilitiesData={facilitiesData} availableRoomsData={availableRoomsData} />
            </div>
            <HotelLocation id="location" locationData={locationData} facilitiesData={facilitiesData} />
            <div id="policies">
              <HotelPolicies policiesData={policiesData} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HotelProfile;
