import { SvgProps } from './SvgProps';

export const TwitterSvg = ({ _width = 12, _height = 13, _color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 21 20" fill="none">
      <g clip-path="url(#clip0_1654_19802)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5 3.89596C19.75 4.223 18.9557 4.43731 18.143 4.53197C18.9963 4.03176 19.6377 3.23743 19.947 2.29796C19.14 2.76896 18.2593 3.10029 17.342 3.27796C16.9553 2.87346 16.4904 2.55176 15.9755 2.33241C15.4607 2.11307 14.9066 2.00064 14.347 2.00196C12.081 2.00196 10.244 3.80996 10.244 6.04197C10.244 6.35796 10.28 6.66597 10.35 6.96197C8.72451 6.8848 7.13294 6.46968 5.67683 5.74309C4.22073 5.0165 2.93204 3.99439 1.893 2.74196C1.52859 3.35636 1.33652 4.05763 1.337 4.77196C1.33808 5.43994 1.5056 6.09713 1.82441 6.68412C2.14322 7.27111 2.60328 7.7694 3.163 8.13397C2.51226 8.11403 1.87538 7.94102 1.304 7.62896V7.67897C1.304 9.63597 2.718 11.269 4.594 11.64C3.98964 11.8013 3.35682 11.8252 2.742 11.71C3.264 13.314 4.779 14.482 6.575 14.515C5.115 15.6405 3.32245 16.249 1.479 16.245C1.15182 16.2447 0.824929 16.2253 0.5 16.187C2.38232 17.3758 4.5637 18.0052 6.79 18.002C14.337 18.002 18.464 11.847 18.464 6.50996C18.464 6.33496 18.46 6.15997 18.452 5.98697C19.2538 5.41791 19.9473 4.70911 20.5 3.89596Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1654_19802">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
