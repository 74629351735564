import './custom-theme.scss';
import './index.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import { jwtDecode } from 'jwt-decode';

// import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import axios, { AxiosResponse } from 'axios';
import Cookie from 'universal-cookie';
import { logInGuest, refreshTokenUrl, X_API_KEY } from './utils/apiRoutes';
import moment from 'moment';
import { AnimatedGalagoLoading } from './components/AnimatedGalagoLoading';
import { ErrorGalagoDialog, SuccessGalagoDialog } from './components/GalagoDialog';
import { DialogBox } from './components/DialogBox';

const cookie = new Cookie();

const env = process.env.REACT_APP_BOOK_FLIGHT_TARGET_ENV;

/**
 * refresh token
 */
const refreshToken = async () => {
  try {
    const response: AxiosResponse = await axios.post(
      refreshTokenUrl(),
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('g-refresh-token')}`,
          // 'x-api-key': X_API_KEY,
        },
      }
    );
    const { accessToken, refreshToken } = response.data;

    cookie.set('g-token', accessToken, { path: '/' });
    cookie.set('g-refresh-token', refreshToken, { path: '/' });

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    return response;
  } catch (error) {
    const { response } = error as { response: AxiosResponse };
    // console.log(response);
    return response;
  }
};

/**
 * refresh token checkpoint
 */
const $_REFRESH_TOKEN = cookie.get('g-refresh-token');
if ($_REFRESH_TOKEN) {
  const { exp } = jwtDecode($_REFRESH_TOKEN) as { exp: number }; // Add type assertion here
  // const currentTime = Date.parse(new Date().toISOString()) / 1000;
  const currentTime = moment().add(1, 'hour').unix();
  // debug environment
  if (env !== 'Production') {
    // console.log('ENV', env);
    console.log('should trigger new refresh token?', exp < currentTime);
    console.log('refresh token exp', new Date(exp * 1000).toISOString());
    console.log('time now', new Date(currentTime * 1000).toISOString());
    console.log('-----------------');
  }
  // refresh token if expired
  if (exp < currentTime) {
    cookie.remove('g-token');
    cookie.remove('g-refresh-token');

    let response: AxiosResponse = await axios.post(logInGuest());
    if (response.status === 200 || response.status === 201) {
      cookie.set('g-token', response.data.accessToken, { path: '/' });
      cookie.set('g-refresh-token', response.data.refreshToken, { path: '/' });
      cookie.set('g-request-cookie', response.headers['set-cookie'], { path: '/' });
    }
  }
}

/**
 * token checkpoint
 */
const $_TOKEN = cookie.get('g-token');
if ($_TOKEN) {
  // localStorage.removeItem('jwt'); // remove the old jwt token
  // console.log('token', $_TOKEN);
  // console.log(refreshToken);
  // know if the token is expired
  const { exp } = jwtDecode($_TOKEN) as { exp: number }; // Add type assertion here
  const currentTime = moment().add(1, 'hour').unix();

  // debug environment
  if (env !== 'Production') {
    // console.log('ENV', env);
    console.log('should trigger new access token?', exp < currentTime);
    console.log('token exp', new Date(exp * 1000).toISOString());
    console.log('time now', new Date(currentTime * 1000).toISOString());
    console.log('-----------------');
  }

  // refresh token if expired
  if (exp < currentTime) {
    refreshToken();
  }

  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Authorization'] = `Bearer ${$_TOKEN}`;
} else {
  let response: AxiosResponse = await axios.post(logInGuest());
  if (response.status === 200 || response.status === 201) {
    cookie.set('g-token', response.data.accessToken, { path: '/' });
    cookie.set('g-refresh-token', response.data.refreshToken, { path: '/' });
    cookie.set('g-request-cookie', response.headers['set-cookie'], { path: '/' });
  } else {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['x-api-key'] = X_API_KEY;
  }
}

if (env !== 'Production') {
  const envCharCount = env?.length as number;
  let dash: string = '';
  for (let i = 0; i < envCharCount; i++) {
    dash += '-';
  }
  console.log(`+------${dash}+\n| ENV ${env} |\n+------${dash}+`);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <>
    {/* <React.StrictMode> */}
    <NextUIProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </NextUIProvider>
    {/* </React.StrictMode> */}
    <AnimatedGalagoLoading />
    <SuccessGalagoDialog />
    <ErrorGalagoDialog />
    <DialogBox />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// save web vital report in a text file
// function saveReport(data: any) {
//   const body = JSON.stringify(data);
//   const blob = new Blob([body], { type: 'text/plain' });
//   const url = URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = 'web-vitals.json';
//   a.click();
//   URL.revokeObjectURL(url);
// }
