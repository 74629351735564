import React from 'react';
import { CountriesHook } from '../hooks/CountriesHook';

interface RestCountriesSelectInputInterface extends React.InputHTMLAttributes<HTMLSelectElement> {
  output: 'nationalities' | 'countries';
  props?: any;
  important?: boolean;
  label: string;
}

export const RestCountriesSelectInput = ({ label, important, output, ...props }: RestCountriesSelectInputInterface) => {
  const { countries, nationalities } = CountriesHook();

  let getOutput = countries;
  if (output === 'nationalities') {
    getOutput = nationalities;
  }

  return (
    <div className="form-floating w-full">
      <select className="form-select w-full" aria-label="" {...props}>
        <option value="" disabled selected>
          {label} {important ? ' *' : ''}
        </option>
        {getOutput?.map((value: { value: string; label: string }, index: any) => {
          return (
            <option value={value.value} key={index} selected={value.value === 'Filipino' || value.value === 'PH' ? true : false}>
              {value.label}
            </option>
          );
        })}
      </select>
      <label htmlFor="floatingSelectGrid">
        {label}
        {important ? ' *' : ''}
      </label>
    </div>
  );
};
