import React, { useState } from 'react';
import { GalagoButton } from '../../../components/Button';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import { dateFormat, formatPriceNoRound } from '../../../utils/data.utils';
import { BaggageSvg } from '../../../resources/svg/BaggageSvg';
import { FlightInfoStore } from '../../../store/FlightInfoStore';
import { CheckInSvg } from '../../../resources/svg/CheckInSvg';
import { LongArrowSvg } from '../../../resources/svg/longArrow.svg';
import { MystiflyFlightSegmentHook } from '../../../hooks/MystiflyFlightSegmentHook';

type FlightListProps = {
  info?: any;
};

export const RoundTripBundleFlightList = ({ info }: FlightListProps) => {
  const { setFlightInfo, setShowFlightInfoModal } = FlightInfoStore();
  const { flightSegments, flightFares, calcTotalTimeDuration } = FlightResultsHook();

  const { segments } = MystiflyFlightSegmentHook();
  const { departureFlightSegmentDetails, returnFlightSegmentDetails, departureAirline, returnAirline } = segments({ FlightType: 'round-trip', FlightDetails: info });

  const departureTimeDuration = calcTotalTimeDuration(departureFlightSegmentDetails?.arrivalDateTime, departureFlightSegmentDetails?.departureDateTime);
  const returnTimeDuration = calcTotalTimeDuration(returnFlightSegmentDetails?.arrivalDateTime, returnFlightSegmentDetails?.departureDateTime);

  // departure
  const formattedDepartureDepartureDate = dateFormat(departureFlightSegmentDetails?.departureDate);
  const formatteDeapartureArrivalDate = dateFormat(departureFlightSegmentDetails?.arrivalDate);
  // arrival
  const formattedReturnDepartureDate = dateFormat(returnFlightSegmentDetails?.departureDate);
  const formattedReturnArrivalDate = dateFormat(returnFlightSegmentDetails?.arrivalDate);

  const { MarketingCarriercode, MarketingFlightNumber } = flightSegments(info?.OriginDestinations);

  const { adult, currency } = flightFares(info?.FlightFares);

  const price = adult.adultBaseFare + adult.adultTax;
  const Baggage = info?.OriginDestinations?.[0]?.ItineraryReference || null;

  // State to track baggage hover
  const [isBaggageTooltipVisible, setIsBaggageTooltipVisible] = useState(false);

  const handleBaggageMouseEnter = () => setIsBaggageTooltipVisible(true);
  const handleBaggageMouseLeave = () => setIsBaggageTooltipVisible(false);

  return (
    <>
      {/* <div className='flight-filter-result-container flex gap-2'> */}

      <div className="flight-result-border  flex" key={info.FareSourceCode}>
        <div className="flight-bundle-container">
          {/* Departure Flight Info */}
          <div className="w-100 departure-flight">
            <div className="airline-logo-container">
              <div className="image-container">
                <img src={departureAirline?.logo} alt="airline logo" loading="lazy" width="50" className="img-fluid" />
              </div>

              <p className="w-100 generalsans-regular flex flex-col">
                {departureAirline?.name}
                {/* <p style={{ color: '#BD0A00', fontSize: '12px', fontWeight: '700' }}>{SeatsRemaining} seats remaining!</p> */}
              </p>
            </div>
            {/* Hidden */}
            <div className="airline-info d-none">
              <span className="w-100 generalsans-regular text-secondary data-head-text">Flight Number</span>
              <span className="w-100 data-text">
                {MarketingCarriercode}
                {MarketingFlightNumber}
              </span>
            </div>
            <div className="departure-location">
              <span className="w-100 data-head-text text-end">{departureFlightSegmentDetails?.departureTime}</span>
              <span className="w-100 data-text">
                {departureFlightSegmentDetails?.departureCode} <span className="text-secondary">|</span> {formattedDepartureDepartureDate}
              </span>
            </div>
            <div className="duration-stops">
              <span className="w-100 data-text"> {departureTimeDuration}</span>
              <LongArrowSvg _width={100} _height={16} />
              <span className="w-100 data-head-text"> {departureFlightSegmentDetails?.stops}</span>
            </div>
            <div className="arrival-location" style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <span className="w-100 data-head-text text-start"> {departureFlightSegmentDetails?.arrivalTime}</span>
              <span className="w-100 data-text">
                {departureFlightSegmentDetails?.arrivalCode} <span className="text-secondary">|</span> {formatteDeapartureArrivalDate}
              </span>
            </div>
          </div>

          {/* Return Flight Info */}
          <div className="w-100 return-flight">
            <div className="airline-logo-container">
              <div className="image-container">
                <img src={returnAirline?.logo} alt="airline logo" loading="lazy" width="50" className="img-fluid" />
              </div>

              <p className="w-100 generalsans-regular flex flex-col">{returnAirline?.name}</p>
            </div>

            {/* Hidden */}
            <div className="airline-info d-none">
              <span className="w-100 generalsans-regular text-secondary data-head-text">Flight Number</span>
              <span className="w-100 data-text">
                {MarketingCarriercode}
                {MarketingFlightNumber}
              </span>
            </div>

            <div className="departure-location" style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <span className="w-100 data-head-text" style={{ textAlign: 'end' }}>
                {returnFlightSegmentDetails?.departureTime}
              </span>
              <span className="w-100 data-text">
                {returnFlightSegmentDetails?.departureCode} <span className="text-secondary">|</span> {formattedReturnDepartureDate}
              </span>
            </div>

            <div className="duration-stops" style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <span className="w-100 data-text"> {returnTimeDuration}</span>
              <LongArrowSvg _width={100} _height={16} />
              <span className="w-100 data-head-text"> {returnFlightSegmentDetails?.stops}</span>
            </div>

            <div className="arrival-location" style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <span className="w-100 data-head-text"> {returnFlightSegmentDetails?.arrivalTime}</span>
              <span className="w-100 data-text">
                {returnFlightSegmentDetails?.arrivalCode} <span className="text-secondary">|</span> {formattedReturnArrivalDate}
              </span>
            </div>
          </div>
        </div>

        <div className="flight-price-info">
          <div
            className="inclusion-section"
            onMouseEnter={handleBaggageMouseEnter}
            onMouseLeave={handleBaggageMouseLeave}
            style={{ border: isBaggageTooltipVisible ? '2px solid #C8E2E8' : '2px solid white' }}
          >
            <span className="d-block">
              <BaggageSvg _width={16} _height={16} _color="#016E7F" />
            </span>
            {Baggage?.CheckinBaggage?.[0]?.Value !== '0PC' && (
              <span className="d-block">
                <CheckInSvg _width={16} _height={16} _color="#016E7F" />
              </span>
            )}
            <p className="included mt-1">included</p>
          </div>

          {/* Baggage Tooltip */}
          {isBaggageTooltipVisible && (
            <div className="baggage-tooltip">
              <div>
                <span>
                  <BaggageSvg _width={24} _height={24} _color="#016E7F" />
                </span>
                <p>
                  The flight includes <span>Cabin Baggage</span>
                </p>
              </div>

              {Baggage?.CheckinBaggage?.[0]?.Value !== '0PC' && (
                <div>
                  <span>
                    <CheckInSvg _width={24} _height={24} _color="#016E7F" />
                  </span>
                  <p>
                    The flight includes <span>Checked-in baggage</span>
                  </p>
                </div>
              )}
            </div>
          )}

          <div className="font-satoshi text-price">
            {currency} {formatPriceNoRound(price)} / pax
          </div>
          <div>
            <GalagoButton
              btn="primary"
              size="sm"
              label={'Select Flight'}
              onClick={() => {
                setFlightInfo(info);
                setShowFlightInfoModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Mobile */}
      {/* <div
        className="oneway-result-item"
        onClick={() => {
          setFlightInfo(info);
          setShowFlightInfoModal(true);
        }}
      >
        <div className="oneway-card">
          <div id="airline-details">
            <div className="airlogo">
              <img src={airline?.logo} alt="airline logo" loading="lazy" />
            </div>
            <p>{airline?.name}</p>
            <span>&#x2022;</span>
            <p>{carrierCode}</p>
            <span>&#x2022;</span>
            <p>{flightNumber}</p>
          </div>
          <hr />
          <Container fluid id="flight-details">
            <Row>
              <Col className="first-Col">
                <p id="from">{departureCode} (Manila)</p>
                <p id="time">06:00</p>
                <p id="day">Sun, Nov 27</p>
              </Col>
              <Col className="second-Col">
                <p id="trip-type">Direct</p>
                <p id="arrow">--------------</p>
                <p id="duration">3h 45m</p>
              </Col>
              <Col className="third-Col">
                <p id="to">{arrivalCode}</p>
                <p id="time">06:00</p>
                <p id="day">Sun, Nov 27</p>
              </Col>
            </Row>
          </Container>
          <hr className="dashed" />
          <div id="baggage-total">
            <div>
              <BaggageSvg _width={15} _height={15} _color="#016E7F" />
            </div>
            <p>
              {currency} {formatPriceNoRound(adult.adultBaseFare)} /pax
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};
