import axios from 'axios';
import React, { useState } from 'react';
import { airlineFlyerApiRoute } from '../utils/apiRoutes';

export const useAirlineFlyerHooks = (MarketingCarriercode: any) => {
  const [freqFlyer, setFreqFlyer] = useState<any>('');

  React.useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(airlineFlyerApiRoute(MarketingCarriercode));
        setFreqFlyer(response.data);
      } catch (error) {
        // console.log(error);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return { freqFlyer };
};
