import React from 'react';
import { useAirlineFlyerHooks } from '../hooks/AirlineFlyerHooks';

interface RestAirlineFlyerSelectInputInterface extends React.InputHTMLAttributes<HTMLSelectElement> {
 
  props?: any;
  important?: boolean;
  label: string;
  carrier?: string;
}

export const AirlineFlyerSelectInput = ({ label, important, carrier,  ...props }: RestAirlineFlyerSelectInputInterface) => {
 
  // const { carrierList } = AirlineFlyerHooks();
  const {freqFlyer} = useAirlineFlyerHooks(carrier)
  // console.log(freqFlyer, 'anu na beh') 
  
  
  return (
    <div className="form-floating w-full">
      <select className="form-select w-full" aria-label="" {...props}>
        <option value="" disabled selected>
          {label} {important ? ' *' : ''}
        </option>
        
            <option value='Airlines'  >
             {freqFlyer.airlineName}
            </option>
         
      </select>
      <label htmlFor="floatingSelectGrid">
        {label}
        {important ? ' *' : ''}
      </label>
    </div>
  );
};
