import React, { useState } from 'react';
import { FlightPassengersStore } from '../../../store/FlightPassengersStore';
import { FlightPassengersHook } from '../../../hooks/FlightPassengersHook';
import { useNavigate } from 'react-router';
import { GalagoButton } from '../../../components/Button';

type AgreeToConditionsProps = {
  passengerCount?: number;
  Flight: { [index: string]: any };
  TransactionId: string;
  ConversationId: string;
  IsDomestic: boolean;
};

const AgreeToConditions = ({ Flight, passengerCount, TransactionId, ConversationId, IsDomestic }: AgreeToConditionsProps) => {
  const navigate = useNavigate();
  const { saveFlightPassengers } = FlightPassengersHook();
  const { contacts, selectedPassengers, savePassengers } = FlightPassengersStore();
  const flightDetails = Flight;
  const transactionId = TransactionId;
  const conversationId = ConversationId;
  const _passengersCount = passengerCount;
  const isDomestic = IsDomestic;
  const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState(false);

  const handleFlightConfirmation = async () => {
    // check the passenger details if complete
    const totalPassengers = flightDetails.passengers.adults + flightDetails.passengers.children + flightDetails.passengers.infants;

    let passengersForm = true;
    let isPassportRequired = false;
    for (let i = 0; i < totalPassengers; i++) {
      if (selectedPassengers[i] === undefined) {
        passengersForm = false;
        break;
      }

      if (selectedPassengers[i]['firstName'] === '' || selectedPassengers[i]['firstName'] === undefined) {
        passengersForm = false;
        break;
      }

      if (selectedPassengers[i]['lastName'] === '' || selectedPassengers[i]['lastName'] === undefined) {
        passengersForm = false;
        break;
      }

      if (selectedPassengers[i]['title'] === '' || selectedPassengers[i]['title'] === undefined) {
        passengersForm = false;
        break;
      }

      if (selectedPassengers[i]['birthDate'] === '' || selectedPassengers[i]['birthDate'] === undefined) {
        passengersForm = false;
        break;
      }

      if (selectedPassengers[i]['nationality'] === '' || selectedPassengers[i]['nationality'] === undefined) {
        passengersForm = false;
        break;
      }

      if (isDomestic === false) {
        if (selectedPassengers[i]['passportNumber'] === '' || selectedPassengers[i]['passportNumber'] === undefined) {
          isPassportRequired = true;
          break;
        }
        if (selectedPassengers[i]['expirationDate'] === '' || selectedPassengers[i]['expirationDate'] === undefined) {
          isPassportRequired = true;
          break;
        }
        if (selectedPassengers[i]['countryIssued'] === '' || selectedPassengers[i]['countryIssued'] === undefined) {
          isPassportRequired = true;
          break;
        }
      }
    }

    if (passengersForm === false) {
      alert('Please complete the passenger form.');
      return;
    }

    if (isPassportRequired === true) {
      alert('Passport is required.');
      return;
    }

    // check if the contact details is empty
    const contact = contacts[0];
    let contactForm = true;
    if (contact['firstName'] === '' || contact['lastName'] === '' || contact['email'] === '') {
      contactForm = false;
    }

    if (contactForm === false) {
      alert('Please complete the contact form.');
      return;
    }

    if (_passengersCount !== selectedPassengers.length) {
      alert('The number of selected passengers does not matched the passenger count, please complete the form');
      return;
    }

    // check if the agreement is true
    if (!agreedToTermsAndConditions) {
      alert('Please confirm that you agreed to our terms and conditions');
      return;
    }

    // check if the the user checked the save passenger consent
    if (savePassengers) {
      // call refresh token
      // console.log(selectedPassengers);
      // saveFlightPassengers({ data: selectedPassengers });
      await saveFlightPassengers({ data: selectedPassengers });
      // console.log(response.data);
    }
    // return;
    // navigate to the next page /booking-summary
    navigate(`/book/summary`, {
      state: {
        contacts: contacts,
        passengers: selectedPassengers,
        flightDetails: flightDetails,
        conversationId: conversationId,
        transactionId: transactionId,
      },
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-[2rem] text-[1rem]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-[37.25rem] flex-row items-center justify-start gap-[0.75rem]">
          <div className="form-check m-0">
            <input className="form-check-input" type="checkbox" value="" onChange={(e) => setAgreedToTermsAndConditions(e.target.checked)} id="flight-agreement" />
            <label className="form-check-label textColor" style={{ display: 'unset' }} htmlFor="flight-agreement">
              <p className="text-start font-regular">
                I have read and agreed to the booking
                <a href="/#/terms-and-conditions" className="no-underline hover:text-[#4FA2B4]" style={{ color: '#016e7f' }}>
                  {' '}
                  Terms and Conditions of GalaGO!
                </a>{' '}
                and
                <a href="/#/privacy-policy" className="no-underline hover:text-[#4FA2B4]" style={{ color: '#016e7f' }}>
                  {' '}
                  Privacy Statement.
                </a>
              </p>
            </label>
          </div>
        </div>
      </div>

      <GalagoButton onClick={handleFlightConfirmation} label={'Next'} btn="primary" className={`search-flight-button p-3 btn-lg`} />
    </div>
  );
};

export default AgreeToConditions;
