import React from 'react';
import AvailableRoomsCardGroup from './AvailableRoomsCardGroup';
import '../../../resources/css/hotel-available-rooms.scss';
import { GalagoButton } from '../../../components/Button';
import { HotelCartStore } from '../../../store/HotelCartStore';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { HotelTransactionHooks } from '../../../hooks/HotelTransactionHooks';
import moment from 'moment';

type HotelAvailableRoomsProps = {
  hotel: { [index: string]: any };
  hotelInfo: { [index: string]: any };
  facilitiesData: { [index: string]: any };
  Currency?: string;
  availableRoomsData: {
    rooms: Array<{
      Name: string[];
      TotalFare: number;
      TotalFareWithMarkUp: number;
    }>;
    nights: number;
  };
  searchCriteria: { [index: string]: any };
};

const HotelAvailableRooms = ({ hotel, Currency, availableRoomsData, hotelInfo, facilitiesData, searchCriteria }: HotelAvailableRoomsProps) => {
  const { selectedHotelRooms, setSelectedHotelRooms } = HotelCartStore();
  const { createHotelTransaction } = HotelTransactionHooks();

  const { rooms, nights } = availableRoomsData;
  const [disableCartButton, setDisableCartButton] = React.useState(false);
  const [totalBaseFare, setTotalBaseFare] = React.useState(0);
  const [totalTaxedFare, setTotalTaxedFare] = React.useState(0);

  React.useEffect(() => {
    let roomsTotalPrice: number = 0;
    let roomsTaxedTotalPrice: number = 0;
    // eslint-disable-next-line
    selectedHotelRooms.map((value: { TotalFare: number; TotalTax: number }, key: React.Key) => {
      roomsTotalPrice += value.TotalFare - value.TotalTax;
      roomsTaxedTotalPrice += value.TotalFare;
    });

    setTotalBaseFare(roomsTotalPrice);
    setTotalTaxedFare(roomsTaxedTotalPrice);
  }, [selectedHotelRooms]);

  const roomCount: number = searchCriteria.rooms;

  const selectedHotelRoomsCount: number = selectedHotelRooms.length;

  const mustSelectRoomCount = roomCount - selectedHotelRoomsCount;

  return (
    <div id="available-rooms" className="w-100">
      <div className="container mb-4 relative">
        {selectedHotelRoomsCount > 0 ? (
          <div id="floating-cart" className="shadow-lg font-satoshi">
            <div className="cart-header">
              <span>Prices might change. You'll see the final price on the next page.</span>
            </div>
            <div className="cart-body">
              <div className="cart-info flex flex-col gap-1">
                <div className="flex flex-row gap-2 text-xl text-primary generalsans-regular font-semibold">
                  <span>
                    {Currency} {formatPriceNoRound(totalBaseFare)}
                  </span>
                  <span>total</span>
                </div>

                <div className="flex flex-row textColor text-base generalsans-regular gap-1">
                  <span>
                    {Currency} {formatPriceNoRound(totalTaxedFare)}
                  </span>
                  <span>with taxes and fees</span>
                </div>
              </div>

              <div className="cart-button flex flex-column gap-2">
                <GalagoButton
                  label={
                    roomCount === selectedHotelRoomsCount
                      ? `Reserve ${selectedHotelRoomsCount} Room${selectedHotelRoomsCount > 1 ? 's' : ''}`
                      : `Select (${roomCount - selectedHotelRoomsCount}) Room${mustSelectRoomCount > 1 ? 's' : ''}`
                  }
                  btn={roomCount !== selectedHotelRooms.length ? 'secondary' : 'primary'}
                  disabled={disableCartButton}
                  onClick={() => {
                    if (roomCount !== selectedHotelRooms.length) {
                      alert(`You have selected only ${selectedHotelRooms.length} room`);
                      return;
                    }

                    setDisableCartButton((prev) => !prev);
                    createHotelTransaction(
                      {
                        location: searchCriteria.location,
                        adults: searchCriteria.adults,
                        rooms: searchCriteria.rooms,
                        checkInDate: moment(searchCriteria.checkInDate).format('YYYY-MM-DD'),
                        checkOutDate: moment(searchCriteria.checkOutDate).format('YYYY-MM-DD'),
                      },
                      { selectedRooms: selectedHotelRooms, hotelInfo: hotelInfo, searchCriteria: searchCriteria, facilitiesData: facilitiesData, hotel: hotel },
                      setDisableCartButton
                    );
                  }}
                  style={{ maxWidth: '200px', marginLeft: 'auto' }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <AvailableRoomsCardGroup
          setSelectedHotelRooms={setSelectedHotelRooms}
          selectedHotelRooms={selectedHotelRooms}
          Currency={Currency}
          hotelInfo={hotelInfo}
          facilitiesData={facilitiesData}
          rooms={rooms}
          nights={nights}
          roomCount={roomCount}
        />
      </div>
    </div>
  );
};

export default HotelAvailableRooms;
