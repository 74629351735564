import React from 'react';
import '../../resources/css/popup.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';

function popupComp() {
  return (
    <div className="position-relative">
      <Container className="popupContainer">
        <div className="innerCont">
          <Col lg={9}>
            <Row className="TotalPrice">
              <h3>Total Price</h3>
            </Row>
            <Row>
              <p>
                1 Room for <span className="price">PHP 2,500.00</span>
                <small> Tax Included</small>
              </p>
            </Row>
          </Col>
          <Col lg={3} className="btn-reserved">
            <Button className="w-full p-4 fs-5">Reserve 1 Room(s)</Button>
          </Col>
        </div>
      </Container>
    </div>
  );
}

export default popupComp;
