import React from 'react';
import { useDisclosure } from '@nextui-org/react';
import ModalTemplate from '../../../components/ui/ModalTemplate';
import CancellationPolicyModal from './CancellationPolicyModal';
import { ClockRoundSvg } from '../../../resources/svg/ClockSvg';
import '../../../resources/css/hotel-policies.scss';

type HotelPoliciesProps = {
  policiesData: {
    checkInTime: string;
    checkOutTime: string;
  };
};

const HotelPolicies = ({ policiesData }: HotelPoliciesProps) => {
  const { isOpen, onOpenChange, onClose } = useDisclosure();

  const closeCancellationPolicyModal = () => {
    onClose();
  };

  const { checkInTime, checkOutTime } = policiesData;
  return (
    <>
      <section id="policies" className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-11 pt-5 border-t-1 border-[#d9d9d9]">
            <h4 className="mb-8 satoshi-medium font-semibold textColor">Hotel Policies</h4>

            <div className="border border-primary rounded-xl px-16 py-4">
              {checkInTime ? (
                <div className="flex w-100 border-bottom py-5">
                  <div className="w-25 flex align-items-start gap-2">
                    <ClockRoundSvg _width={23} _height={23} _color="#016E7F" />
                    <div>Check-in time</div>
                  </div>
                  <div>Starts at {checkInTime}</div>
                </div>
              ) : null}

              {checkOutTime ? (
                <div className="flex w-100 border-bottom py-5">
                  <div className="w-25 flex align-items-start gap-2">
                    <ClockRoundSvg _width={23} _height={23} _color="#016E7F" />
                    <div>Check-out time</div>
                  </div>

                  <div>Until {checkOutTime}</div>
                </div>
              ) : null}

              <div className="flex w-100 py-5">
                <div className="w-25 flex align-items-start gap-2">
                  <ClockRoundSvg _width={23} _height={23} _color="#016E7F" />
                  <div>Cancellation Policy</div>
                </div>

                <div className="w-75">
                  Cancellation policies vary depending on the type of accommodation. <br />
                  Kindly review the specific <span className="italic text-primary font-medium"> conditions associated with each room option</span> while making your selection.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[584.5px] w-[500px]">
        <CancellationPolicyModal onClose={closeCancellationPolicyModal} />
      </ModalTemplate>
    </>
  );
};

export default HotelPolicies;
