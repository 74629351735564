import React from 'react';
import HotelCardContainer from './HotelCardContainer';

type AvailableRoomsCardGroupProps = {
  rooms: Array<{
    Name: string[];
    TotalFare: number;
    TotalFareWithMarkUp: number;
  }>;
  nights: number;
  hotelInfo: { [index: string]: any };
  facilitiesData: { [index: string]: any };
  Currency?: string;
  setSelectedHotelRooms: (arg: { [index: string]: any }) => void;
  selectedHotelRooms: { [index: string]: any };
  roomCount: number;
};

const AvailableRoomsCardGroup = ({ Currency, rooms, nights, hotelInfo, facilitiesData, setSelectedHotelRooms, selectedHotelRooms, roomCount }: AvailableRoomsCardGroupProps) => {
  let { images } = hotelInfo;
  const roomImage = images[0];

  return (
    <div className="row justify-content-center position-relative">
      <div className="flex flex-column mb-10 gap-1 col-11">
        <h3 className="p-0 text-2xl satoshi-medium font-semibold textColor m-0">Available Rooms</h3>
        <p className="p-0 text-secondary text-base generalsans-medium m-0">Prices might change. You'll see the final price on the next page.</p>
      </div>

      {rooms
        ? rooms.map((room: { [index: string]: any }, index) => {
            const foundInSelection = selectedHotelRooms.find((xRoom: { BookingCode: string }) => xRoom.BookingCode === room.BookingCode);
            const isSelected = foundInSelection ? true : false;
            const disableButton = selectedHotelRooms.length >= roomCount ? true : false;
            return (
              <HotelCardContainer
                setSelectedHotelRooms={setSelectedHotelRooms}
                isSelected={isSelected}
                Currency={Currency}
                key={index}
                hotelInfo={hotelInfo}
                facilitiesData={facilitiesData}
                roomImage={roomImage}
                room={room}
                nights={nights}
                disableButton={disableButton}
              />
            );
          })
        : null}
    </div>
  );
};

export default AvailableRoomsCardGroup;
