import React from 'react';
import PassengerTabs from './PassengerTabs';
import { UserStore } from '../../../store/UserStore';
import { FlightPassengersStore } from '../../../store/FlightPassengersStore';

type PassengerDetailsProps = {
  PassengerCount: number;
  Passengers: { [index: string]: any };
  Flight: { [index: string]: any };
  FlightType: 'one-way' | 'round-trip';
  IsDomestic: boolean;
  carrierDetails?: string;
};



const PassengerDetails = ({ PassengerCount, Passengers, Flight, FlightType, IsDomestic, carrierDetails }: PassengerDetailsProps) => {
  const { isLoggedIn } = UserStore();
  const { setSavePassengers } = FlightPassengersStore();



  return (
    <div id="passenger-details" className="self-stretch flex flex-col items-start justify-start gap-[2rem] text-left text-text-colors-text-primary font-heading-3">
      <div id="passenger-details-title" className="self-stretch flex flex-col py-2.5 px-0 items-start justify-center gap-2.5 textColor">
        <span className="font-satoshi font-bold">Passenger Details</span>
        <div className="text-[1rem] leading-[1.31rem] font-medium font-footnote">Fill in the details to finalize your booking!</div>
      </div>

      <PassengerTabs PassengerCount={PassengerCount} Passengers={Passengers} isDomesticFlight={IsDomestic} Flights={Flight} FlightType={FlightType} flyer={carrierDetails}/>

      {/* passenger details TNC */}
      {isLoggedIn && (
        <>
          <div className="form-check py-0 m-0">
            <input className="form-check-input" type="checkbox" value="" onChange={(e) => setSavePassengers(e.target.checked)} id="flexCheckDefault" />
            <label className="form-check-label" style={{ display: 'unset' }} htmlFor="flexCheckDefault">
              I agree all the information I provided will be used for my future flight bookings.
            </label>
          </div>
        </>
      )}

      {!isLoggedIn && (
        <div className="flex flex-col items-center justify-center">
          <div className="w-[37.25rem] flex flex-row items-center justify-start gap-[0.75rem]">
            <div className="flex flex-col relative">
              <p>
                Want to save your passenger details for future bookings?{' '}
                <a href="/#" className="no-underline cursor-pointer">
                  Create Account or Log-in to an existing account on GalaGO!
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      <hr className="w-full text-gray-200 p-0 m-0" />
    </div>
  );
};

export default PassengerDetails;
