import { SvgProps } from './SvgProps';

export const PassengerSvg = ({ _width = 12, _height = 13, _color = '#A8A8A8' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12.6333C3 12.6333 4.71387 9.72593 8.08713 9.80556C11.3127 9.88171 13 12.7004 13 12.7004" stroke={_color} strokeWidth="1.71429" strokeLinecap="round" />
      <path
        d="M11.254 6.45677C11.254 8.25386 9.79714 9.7107 8.00005 9.7107C6.20295 9.7107 4.74612 8.25386 4.74612 6.45677C4.74612 4.65968 6.20295 3.20285 8.00005 3.20285C9.79714 3.20285 11.254 4.65968 11.254 6.45677Z"
        stroke={_color}
        strokeWidth="1.71429"
      />
    </svg>
  );
};
