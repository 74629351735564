import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Calendar from 'react-calendar';
import { COLOR } from '../../../custom-theme';
import { SearchPageHook } from '../../../hooks/SearchPageHook';
import { CalendarSvg } from '../../../resources/svg/CalendarSvg';
import { CloseCircleFilledSvg } from '../../../resources/svg/CloseSvg';
import { CircleGallySvg } from '../../../resources/svg/GallySvg';
import { LocationSvg } from '../../../resources/svg/LocationSvg';
import { hotelStore } from '../../../store/HotelStore';
import { FloatingIconFormControl, FormControl } from '../../../components/FormControl';
// import SearchInfoGroup from './SearchInfoGroup';
import { GalagoButton } from '../../../components/Button';
import { hotelSearch } from '../../../utils/apiRoutes';
import { IoIosPaperPlane } from 'react-icons/io';
import { TiLocation } from 'react-icons/ti';
import { HotelSearchSvg } from '../../../resources/svg/HotelSearchSvg';
import { HotelFormStore } from '../../../store/HotelFormStore';
import { PassengerSvg } from '../../../resources/svg/PassengerSvg';
import { ChevronDownV2 } from '../../../resources/svg/ChevronDownV2';
import { useNavigate } from 'react-router';
import { SearchSvg } from '../../../resources/svg/SearchSvg';
import { HotelFiltersStore } from '../../../store/HotelFiltersStore';

type HotelsSearchboxProps = {
  inputData: {
    location: string;
    checkInDate: string;
    checkOutDate: string;
    adults: number;
    rooms: number;
    longitude: number;
    latitude: number;
  };
  setInputData: React.Dispatch<
    React.SetStateAction<{
      location: any;
      checkInDate: any;
      checkOutDate: any;
      adults: any;
      rooms: any;
      longitude: any;
      latitude: any;
    }>
  >;
  setEnableQuery: any;
  searchParams: URLSearchParams;
};

const HotelsSearchbox = ({ searchParams, inputData, setInputData, setEnableQuery }: HotelsSearchboxProps) => {
  // eslint-disable-next-line
  const navigate = useNavigate();
  // const { isHotelSearchBtnClicked, toggleSearchBtn } = hotelStore();
  const { hotelLocations } = SearchPageHook();
  const destination = useRef<any>(null);
  const { filterReset, setSelectedPriceRangeLabel, setPriceRange, setSelectedPriceRange } = HotelFiltersStore();
  const { hotels, setHotels, selectedHotel, setSelectedHotel, checkInDate, checkOutDate, setCheckInDate, setCheckOutDate, rooms, setRooms, adults, setAdults, children, setChildren }: any =
    HotelFormStore();

  // console.log(checkOutDate);

  const getCheckInDate = searchParams.get('checkInDate');
  const getCheckOutDate = searchParams.get('checkOutDate');
  const getAdults = searchParams.get('adults');
  const getRooms = searchParams.get('rooms');
  const getLatitude = searchParams.get('latitude');
  const getLongitude = searchParams.get('longitude');
  const getKeyword = searchParams.get('keyword');
  const getFilterKey = searchParams.get('filterKey');
  const getSelectedPriceRange = searchParams.get('priceRange');
  const getHotelStars = searchParams.get('hotelStars');
  const getHotelFacilities = searchParams.get('hotelFacilities');
  const getBedPreferences = searchParams.get('bedPreferences');
  const getSortedBy = searchParams.get('sortedBy');

  // console.log({
  //   getCheckInDate,
  //   getCheckOutDate,
  //   getAdults,
  //   getRooms,
  //   getLatitude,
  //   getLongitude,
  //   getKeyword,
  //   getFilterKey,
  //   getSelectedPriceRange,
  //   getHotelStars,
  //   getHotelFacilities,
  //   getBedPreferences,
  //   getSortedBy,
  // });

  // const [infants, setInfants] = useState(0);
  const maxPassengers = 9;
  // eslint-disable-next-line
  const [maxPassengerCount, setMaxPassengerCount] = useState(0);
  // eslint-disable-next-line
  const [maxDate, setMaxDate] = useState<any>();

  // eslint-disable-next-line
  const [searchInfoGroupData, setSearchInfoGroupData] = useState(inputData);
  // console.log(searchInfoGroupData)
  // const { checkInDate , checkOutDate , setCheckInDate, setCheckOutDate } : any = HotelFormStore();

  //* New States
  const currentDate = moment(new Date()).format('MMM DD, YYYY');
  const [defaultCheckInDate, setDefaultCheckInDate] = useState(currentDate);
  const [defaultCheckOutDate, setDefaultCheckOutDate] = useState('');
  // eslint-disable-next-line
  const [isHotelActive, setIsHotelActive] = useState(false);
  const [isCheckInActive, setIsCheckInActive] = useState(false);
  const [isCheckOutActive, setIsCheckOutActive] = useState(false);
  const [isPassengerActive, setIsPassengerActive] = useState(false);
  const [isPassengerChanged, setIsPassengerChanged] = useState(false);
  const [maxGuestsCount, setMaxGuestCount] = useState(0);
  const [showCheckInDate, setShowCheckInDate] = useState(false);
  const [showCheckOutDate, setShowCheckOutDate] = useState(false);
  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const checkInRef = useRef(null);
  const maxRooms = 9;

  //* New States

  //! Search Nearby Hotel
  const initialData = {
    index: 0,
    name: 'Search nearby hotel',
    isCity: true,
    address: '',
    hotelCode: '',
    fullLocation: 'Nearby Places',
  };

  const [mappedHotels, setMappedHotels] = useState([initialData]);
  const [locationCoords, setLocationCoords] = useState<{ latitude: number | null; longitude: number | null }>({
    latitude: 0,
    longitude: 0,
  });
  const [isInitialDataSelected, setIsInitialDataSelected] = useState(false);
  const [locationAccessDenied, setLocationAccessDenied] = useState(false);

  useEffect(() => {
    if (hotels !== undefined && hotels.tag !== undefined) {
      const foundHotels = hotels.tag;
      let _locationsAndHotels = [{ ...initialData }];
      let _i = 1; // Start from 1 since the initial data is already added

      foundHotels.forEach((location: any) => {
        if (location.location && location.fullLocation) {
          // Ensure the location has the necessary properties
          _locationsAndHotels[_i] = {
            index: _i,
            name: location.location,
            isCity: true,
            address: '',
            hotelCode: '',
            fullLocation: location.fullLocation,
          };
          _i++;

          if (location.hotels && Array.isArray(location.hotels)) {
            // Check if location.hotels is defined and an array
            location.hotels.forEach((hotel: any) => {
              if (hotel.HotelName && hotel.CityName && hotel.CountryName && hotel.HotelCode) {
                // Ensure hotel has the necessary properties
                _locationsAndHotels[_i] = {
                  index: _i,
                  name: hotel.HotelName,
                  isCity: false,
                  address: `${hotel.CityName}, ${hotel.CountryName}`,
                  hotelCode: hotel.HotelCode,
                  fullLocation: location.fullLocation,
                };
                _i++;
              }
            });
          }
        }
      });

      setMappedHotels(_locationsAndHotels);
    }
    // eslint-disable-next-line
  }, [hotels]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isInitialDataSelected && event.key === 'Backspace') {
        event.preventDefault();
      }
    };

    if (isInitialDataSelected) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isInitialDataSelected]);

  const handleSelectHotel = (e: any) => {
    const selectedIndex = e[0]?.index;
    const selectedHotel = mappedHotels[selectedIndex];
    setSelectedHotel(e[0]?.name);

    if (selectedHotel?.name === initialData?.name) {
      setIsInitialDataSelected(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log('Latitude:', position.coords.latitude);
          // console.log('Longitude:', position.coords.longitude);
          setLocationCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationAccessDenied(false);
        },
        (error) => {
          console.error('Error getting location:', error);
          if (error.code === error.PERMISSION_DENIED) {
            setLocationAccessDenied(true);
          }
        }
      );
    } else {
      setIsInitialDataSelected(false);
    }
  };

  let searchUrl: string = hotelSearch();
  searchUrl += `?&checkInDate=${checkInDate}&checkOutDate=${checkOutDate as string}&adults=${adults}&rooms=${rooms}&longitude=${locationCoords.longitude}&latitude=${locationCoords.latitude}`;

  const updateRoomsAndAdultsCount = (count: number, triggeredFrom: string, operation?: string) => {
    if (count === 9 && triggeredFrom === 'room') {
      setRooms(9);
      setAdults(27);
      return;
    }

    if (triggeredFrom === 'adult' && count === 27) {
      setRooms(9);
      setAdults(27);
      return;
    }

    if (triggeredFrom === 'room') {
      if (operation === 'plus') {
        setAdults((prev: any) => prev + 1);
      } else {
        let adultsCount = adults;
        if (adultsCount >= 1) setAdults((prev: any) => prev - 1);
      }
    }
  };

  const onClickButtonSearch = () => {
    filterReset();
    setSelectedPriceRange({ start: 0, end: 0 });
    setSelectedPriceRangeLabel({ start: 0, end: 0 });
    const payloadString = isInitialDataSelected
      ? `checkInDate=${checkInDate ? checkInDate : defaultCheckInDate}&checkOutDate=${checkOutDate ? checkOutDate : defaultCheckOutDate}&adults=${adults}&rooms=${rooms}&latitude=${
          locationCoords.latitude
        }&longitude=${locationCoords.longitude}`
      : `keyword=${selectedHotel}&checkInDate=${checkInDate ? checkInDate : defaultCheckInDate}&checkOutDate=${checkOutDate ? checkOutDate : defaultCheckOutDate}&adults=${adults}&rooms=${rooms}`;

    const finalSearchUrl = `${searchUrl}&${payloadString}`;
    navigate(`/hotel/results?${payloadString}`, {
      state: {
        searchUrl: finalSearchUrl,
      },
    });
  };

  // search button logic
  // eslint-disable-next-line
  const [disableButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (selectedHotel && checkInDate && checkOutDate && adults) {
      setDisabledButton(false);
    }
    // eslint-disable-next-line
  }, [selectedHotel, checkInDate, checkOutDate]);

  useEffect(() => {
    setDefaultCheckOutDate(moment(defaultCheckInDate).add(1, 'days').format('MMM DD, YYYY'));
    if (checkInDate !== '') {
      setDefaultCheckOutDate(moment(checkInDate).add(1, 'days').format('MMM DD, YYYY'));
    }

    if (checkOutDate !== '') {
      if (moment(checkInDate).isSameOrAfter(moment(checkOutDate ? checkOutDate : defaultCheckOutDate))) {
        setCheckOutDate(moment(checkInDate).add(1, 'days').format('MMM DD, YYYY'));
      }
    }
    // eslint-disable-next-line
  }, [checkInDate]);
  useEffect(() => {
    destination.current.inputNode.style.borderColor = '';
    if (selectedHotel) {
      destination.current.inputNode.style.borderColor = '#4FA2B4';
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex align-items-center justify-center position-relative bg-primary p-5">
      <div className="position-absolute top-[-20px] rounded-xl bg-white my-5  py-[32px] px-[50px] drop-shadow-md w-[1515px] border-1 border-solid border-[#ebebeb]">
        <div className="flex justify-between gap-2">
          {/* destination input */}
          <div className="flex flex-col items-start justify-start gap-2.5 w-[338px] flex-fill">
            <b className="relative generalsans-medium textColor text-sm">Destination or Hotel</b>
            <span className="position-relative d-block w-100 " id="hotel-destination-holder">
              <LocationSvg _color={selectedHotel || isInitialDataSelected ? '#4FA2B4' : '#ADADAD'} className="location-svg" />
              {selectedHotel !== '' ? (
                <CloseCircleFilledSvg
                  _color={'#4FA2B4'}
                  className="clear-text-svg"
                  onClick={() => {
                    setSelectedHotel('');
                    destination.current.state.text = '';
                    destination.current.state.selected = [];
                    destination.current.inputNode.value = '';
                  }}
                />
              ) : null}
              <AsyncTypeahead
                ref={destination}
                id="async-hotel"
                placeholder="City or Property Name"
                isLoading={false}
                filterBy={() => true}
                minLength={1}
                onSearch={(query: string) => {
                  hotelLocations(query).then((response: { [index: string]: any }) => {
                    setHotels(response);
                  });
                }}
                onChange={handleSelectHotel}
                labelKey={(option: any) => (option?.isCity ? option?.fullLocation : option?.name)}
                options={mappedHotels}
                defaultInputValue={isInitialDataSelected ? 'Nearby Places' : selectedHotel}
                renderMenuItemChildren={(value: any) => {
                  const isNearbyHotel = value?.name === 'Search nearby hotel';
                  return (
                    <div
                      style={{ padding: '10px' }}
                      className={!value?.isCity ? 'ps-3 d-flex gap-1.5 z-10 align-items-start generalsans-medium' : 'd-flex gap-1.5 align-items-baseline generalsans-medium'}
                    >
                      <div className={value?.isCity ? 'pt-0.2 flex align-baseline' : 'ps-3 flex align-baseline'}>
                        {isNearbyHotel ? (
                          <IoIosPaperPlane width={'11px'} height={'11px'} />
                        ) : value?.isCity ? (
                          <TiLocation />
                        ) : (
                          // eslint-disable-next-line react/jsx-no-undef
                          <HotelSearchSvg />
                        )}
                      </div>
                      <div className="d-flex flex-column gap-0.5">
                        <div>{isNearbyHotel ? 'Search nearby hotel' : value?.isCity ? value.fullLocation : value.name}</div>
                        <div className="leading-normal" style={{ fontSize: '14px', color: '#333333', fontWeight: '400', whiteSpace: 'normal' }}>
                          <span>{value.address}</span>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </span>
          </div>

          {/* check-in */}
          <div ref={checkInRef} className="flex flex-col items-start justify-start gap-2.5 w-[164px] flex-fill">
            <b className="relative generalsans-medium textColor text-sm">Check-in Date</b>
            <FloatingIconFormControl
              onClick={(e: any) => {
                setShowCheckInDate(true);
                setShowCheckOutDate(false);
                setShowPassengerForm(false);
              }}
              value={checkInDate === '' ? defaultCheckInDate : checkInDate}
              type={'text'}
              icon={<CalendarSvg _color={isCheckInActive || selectedHotel || checkInDate ? '#4FA2B4' : '#ADADAD'} />}
              style={{
                border: isCheckInActive || selectedHotel || checkInDate ? '2px solid #4FA2B4' : '',
                cursor: 'pointer',
              }}
            />
            {showCheckInDate && (
              <div
                className="absolute w-[280px] top-[6rem] flex z-10"
                onMouseLeave={() => {
                  setShowCheckInDate(false);
                }}
              >
                <Calendar
                  onChange={(e: any) => {
                    setShowCheckInDate(false);
                    setCheckInDate(moment(e).format('MMM DD, YYYY'));
                    setMaxDate(moment(e).add(29, 'day').format('YYYY-MM-DD'));
                  }}
                  minDate={new Date()}
                  maxDate={new Date(moment().add(1, 'year').format('YYYY-MM-DD'))}
                  className="hotel-check-in-date"
                />
              </div>
            )}
          </div>

          {/* checkout */}
          <div className="flex flex-col items-start justify-start gap-2.5 w-[164px] flex-fill">
            <b className="relative generalsans-medium textColor text-sm">Check-out Date</b>
            <FloatingIconFormControl
              onClick={() => {
                setShowCheckOutDate(true);
                setShowCheckInDate(false);
                setShowPassengerForm(false);
              }}
              value={checkOutDate === '' ? defaultCheckOutDate : checkOutDate}
              type={'text'}
              icon={<CalendarSvg _color={isCheckOutActive || selectedHotel || checkOutDate ? '#4FA2B4' : '#ADADAD'} />}
              placeholder={checkOutDate === '' ? defaultCheckOutDate : checkOutDate}
              readOnly={true}
              onFocus={() => setIsCheckOutActive(true)}
              onBlur={() => setIsCheckOutActive(false)}
              style={{
                border: isCheckOutActive || selectedHotel || checkOutDate ? '2px solid #4FA2B4' : '',
                cursor: 'pointer',
              }}
            />

            {showCheckOutDate && (
              <div className="absolute w-[280px] top-[6rem] flex z-10" onMouseLeave={() => setShowCheckOutDate(false)}>
                <Calendar
                  onChange={(e: any) => {
                    setShowCheckOutDate(false);
                    setCheckOutDate(moment(e).format('MMM DD, YYYY'));
                  }}
                  defaultActiveStartDate={checkInDate ? new Date(checkInDate) : new Date()}
                  minDate={new Date(moment(checkInDate).add(1, 'day').format('YYYY-MM-DD'))}
                  maxDate={new Date(moment(checkInDate).add(29, 'days').format('YYYY-MM-DD'))}
                  className="hotel-check-in-date"
                />
              </div>
            )}
          </div>

          {/* guests */}
          <div className="flex flex-col  justify-start w-[338px] flex-fill">
            <FloatingIconFormControl
              type="text"
              icon={<PassengerSvg _color={selectedHotel || isPassengerActive || isPassengerChanged ? '#4FA2B4' : '#ADADAD'} />}
              caret={
                <ChevronDownV2
                  style={showPassengerForm ? { transform: 'rotate(180deg)', transition: 'transform 0.3s ease, fill 0.3s ease' } : { transition: 'transform 0.3s ease, fill 0.3s ease' }}
                  _color={selectedHotel || isPassengerActive || isPassengerChanged ? '#4fa2b4' : '#adadad'}
                />
              }
              onClick={() => {
                setShowPassengerForm(true);
                setShowCheckOutDate(false);
                setShowCheckInDate(false);
              }}
              onFocus={() => setIsPassengerActive(true)}
              onBlur={() => setIsPassengerActive(false)}
              value={`${adults} ${adults > 1 ? 'Adults' : 'Adult'}, ${children} ${children > 1 ? 'Children' : 'Child'}, ${rooms} ${rooms > 1 ? 'Rooms' : 'Room'}`}
              label={'Guests and Rooms'}
              style={{
                border: selectedHotel || isPassengerActive || isPassengerChanged ? '2px solid #4FA2B4' : '',
                cursor: 'pointer',
              }}
              readOnly={true}
            />
            {showPassengerForm && (
              <div
                id="passenger-details-holder"
                className="position-absolute !z-50 bg-body d-flex flex-column align-items-start justify-content-center top-[6rem]"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '32px',
                  width: '388px',
                  gap: '',
                }}
                onMouseLeave={() => {
                  setShowPassengerForm(false);
                }}
              >
                <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                  <div className="gap-2 d-flex justify-content-center align-items-center">
                    <div className="passenger-count-label">
                      <span className="w-100 d-inline-block generalsans-medium">Rooms</span>
                      <span className="w-100 d-inline-block"></span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <button
                      className="passenger-count-btn"
                      disabled={rooms === 1 ? true : false}
                      onClick={() => {
                        // setRooms((prev) => prev - 1);
                        let roomCount = rooms;
                        setRooms(roomCount === 0 ? 0 : --roomCount);
                        updateRoomsAndAdultsCount(roomCount, 'room');
                        setIsPassengerChanged(true);
                      }}
                      style={{
                        borderColor: rooms === 1 ? 'transparent' : COLOR.primary,
                      }}
                    >
                      {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "12px" }}>
                    <button
                      className="passenger-count-btn adult-control"
                      disabled={adults === 1 ? true : false}
                      onClick={() => {
                        let adultCount = adults;
                        setAdults(adultCount == 0 ? 0 : --adultCount);
                        updateRoomsAndAdultsCount(adultCount, "adult");
                      }}
                      style={{
                        borderColor: adults === 1 ? "transparent" : COLOR.primary,
                      }}
                    > */}
                      -
                    </button>
                    <span style={{ width: '10px' }}>{rooms}</span>
                    <button
                      className="passenger-count-btn"
                      disabled={rooms === 9 || maxGuestsCount === maxRooms ? true : false}
                      onClick={() => {
                        // setRooms((prev) => prev + 1);
                        let roomCount = rooms;
                        setRooms(roomCount === 9 ? 9 : ++roomCount);
                        updateRoomsAndAdultsCount(roomCount, 'room', 'plus');
                        setIsPassengerChanged(true);
                      }}
                      style={{
                        color: maxGuestsCount === maxRooms ? '#333' : COLOR.primary,
                        borderColor: maxGuestsCount === maxRooms ? 'transparent' : COLOR.primary,
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                  <div className="gap-2 d-flex justify-content-center align-items-center">
                    <div className="passenger-count-label">
                      <span className="w-100 d-inline-block generalsans-medium">Adults</span>
                      <span className="w-100 d-inline-block generalsans-regular">Ages 18 and above</span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <button
                      className="passenger-count-btn adult-control"
                      disabled={adults === 1 ? true : false}
                      onClick={() => {
                        let adultCount = adults;
                        setAdults(adultCount === 0 ? 0 : --adultCount);
                        updateRoomsAndAdultsCount(adultCount, 'adult');
                        setIsPassengerChanged(true);
                      }}
                      style={{
                        borderColor: adults === 1 ? 'transparent' : COLOR.primary,
                      }}
                    >
                      -
                    </button>
                    <span style={{ width: '10px' }}>{adults}</span>
                    <button
                      className="passenger-count-btn adult-control"
                      disabled={adults >= 27 || maxGuestsCount === maxRooms ? true : false}
                      onClick={() => {
                        let adultCount = adults;
                        setAdults(adultCount === 27 ? 27 : ++adultCount);
                        updateRoomsAndAdultsCount(adultCount, 'adult', 'plus');
                        setIsPassengerChanged(true);
                      }}
                      style={{
                        color: maxGuestsCount === maxRooms ? '#333' : COLOR.primary,
                        borderColor: maxGuestsCount === maxRooms ? 'transparent' : COLOR.primary,
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                  <div className="gap-2 d-flex justify-content-center align-items-center">
                    <div className="passenger-count-label">
                      <span className="w-100 d-inline-block generalsans-medium">Children</span>
                      <span className="w-100 d-inline-block generalsans-regular">Ages 2 to 12</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <button
                      className="passenger-count-btn"
                      disabled={children === 0 ? true : false}
                      onClick={() => {
                        let childrenCount = children;
                        setChildren(childrenCount === 0 ? 0 : --childrenCount);
                        updateRoomsAndAdultsCount(childrenCount, 'children');
                        setIsPassengerChanged(true);
                      }}
                      style={{
                        borderColor: children === 0 ? 'transparent' : COLOR.primary,
                      }}
                    >
                      -
                    </button>
                    <span style={{ width: '10px' }}>{children}</span>
                    <button
                      className="passenger-count-btn"
                      disabled={children === 9 || maxGuestsCount === maxRooms}
                      onClick={() => {
                        let childrenCount = children;
                        // Increment children count if it's less than 9
                        const newChildrenCount = childrenCount < 9 ? childrenCount + 1 : childrenCount;

                        setChildren(newChildrenCount); // Update Zustand state
                        updateRoomsAndAdultsCount(newChildrenCount, 'children', 'plus');
                        setIsPassengerChanged(true); // Additional logic
                      }}
                      style={{
                        color: maxGuestsCount === maxRooms ? '#333' : COLOR.primary,
                        borderColor: maxGuestsCount === maxRooms ? 'transparent' : COLOR.primary,
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="hotel-guest-room-info">
                  <div>
                    <CircleGallySvg _width={64} _height={64} />
                  </div>
                  <div>
                    <p className="satoshi-medium">For more than 8 rooms, we advise you to contact our support team to avail better deals for group bookings.</p>
                    <a className="generalsans-medium" href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                      Contact GalaGO! Team{' '}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Search Button */}
          <div className="flex flex-col items-start justify-end">
            <GalagoButton
              onClick={onClickButtonSearch}
              btn="primary"
              className={`!w-[200px] !h-[50px] pt-[12px] ms-auto`}
              icon={
                <span className="me-2 mb-1">
                  <SearchSvg _color="#fff" />
                </span>
              }
              label="SEARCH"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelsSearchbox;
