import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { EmailDialog } from '../pages/login/EmailDialog';
import { PasswordDialog } from '../pages/login/PasswordDialog';
import { SignUpDialog } from '../pages/login/SignUpDialog';
// import { RegistrationDialog } from '../pages/registration/RegistrationDialog';
import '../resources/css/alert-confirm-email.scss';
import '../resources/css/navbarv3.scss';
import { ChevronDownSvg } from '../resources/svg/ChevronDownSvg';
import { LogoSvg } from '../resources/svg/Logo';
import { EmailDialogStore } from '../store/EmailDialogStore';
import { PasswordDialogStore } from '../store/PasswordDialogStore';
import { SignUpDialogStore } from '../store/SignupDialogStore';
import { UserStore } from '../store/UserStore';
import { GalagoButton } from './Button';
import { ASSETS_API } from '../utils/apiRoutes';
import EmailConfirmationDialog from '../pages/login/EmailConfirmationDialog';
import PendingVerificationDialog from '../pages/login/PendingVerificationDialog';
import EmailSentDialog from '../pages/login/EmailSentDialog';

interface Props {
  photo?: string;
}
// TODO: refactor this component
export const NavBarTransparent = ({ photo }: Props) => {
  const placeholder = photo ? photo : `${ASSETS_API().resources.images}/photo-placeholder.png`;
  const location = useLocation();
  // stores
  const { info, isLoggedIn, isGuest } = UserStore();
  const { showSignUpDialog, setShowSignUpDialog } = SignUpDialogStore();
  const { showEmailDialog, setShowEmailDialog } = EmailDialogStore();
  const { showPasswordDialog, setShowPasswordDialog } = PasswordDialogStore();
  // active state
  const pathLocation = useLocation();
  // hooks
  const [hideHeaderLinks, setHideHeaderLinks] = useState(false);

  useEffect(() => {
    const pathsToHide = ['/book/flight', '/booking/summary', '/payment-confirmation'];
    if (pathsToHide.includes(location.pathname)) {
      setHideHeaderLinks(true);
    } else setHideHeaderLinks(false);
  }, [location]);

  const [loggedFirstName, setLoggedFirstName] = useState('');
  const [loggedLastName, setLoggedLastName] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(placeholder);

  useEffect(() => {
    const appendToPreventCache = '?id=' + Math.random();
    if (photo?.length) setProfilePhoto(photo + appendToPreventCache);
    else setProfilePhoto(placeholder);
    // eslint-disable-next-line
  }, [photo]);

  useEffect(() => {
    const appendToPreventCache = '?id=' + Math.random();
    if (info !== undefined) {
      const infoData = info;
      setLoggedFirstName(infoData?.firstName); // Access the 'firstName' property
      setLoggedLastName(infoData?.lastName);
      if (infoData?.profilePic) setProfilePhoto(infoData?.profilePic + appendToPreventCache);
      else setProfilePhoto(placeholder);
    }
    // eslint-disable-next-line
  }, []);

  // mobile nav
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  //   change color
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener('scroll', changeColor);

  //   close menu
  const closeMenu = () => setClick(false);

  // Dropdown Toggle Icon
  const [dropdownToggle, setActiveDropdownToggle] = useState(false);

  // code upgrade

  return (
    <>
      <div className={color ? 'header header-bg' : 'header'} style={{ zIndex: 2 }}>
        <nav className="navbar ">
          <Container>
            <a href="/" className="logo">
              <LogoSvg />
            </a>
            <div className="hamburger" onClick={handleClick}>
              {click ? <FaTimes size={30} style={{ color: '#ffffff' }} /> : <FaBars size={30} style={{ color: '#ffffff' }} />}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              {!hideHeaderLinks && (
                <>
                  <li className={pathLocation.pathname === '/' ? 'navbar-active' : 'nav-item'}>
                    <a href="/#" onClick={closeMenu} style={{ textDecoration: 'none' }}>
                      Home
                    </a>
                  </li>
                  {/* HIDDEN DUE TO UNAVAILABILITY OF CONTENT */}
                  {/* <li className="nav-item">
                    <a href="/#" onClick={closeMenu} style={{ textDecoration: 'none' }}>
                      Promos
                    </a>
                  </li> */}
                  <li className={pathLocation.pathname === '/blogs' ? 'navbar-active' : 'nav-item '}>
                    <a href="/#/blogs" onClick={closeMenu} style={{ textDecoration: 'none' }}>
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="https://www.walktheplanet.com" onClick={closeMenu} style={{ textDecoration: 'none' }}>
                      About Us
                    </a>
                  </li>
                </>
              )}

              <li className="nav-item position-relative">
                {isLoggedIn && !isGuest ? (
                  <>
                    <a className="nav-link  d-flex align-items-center" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setActiveDropdownToggle(!dropdownToggle)}>
                      <img width={40} src={profilePhoto} alt="" className="profile-img" />
                      <div className="flex flex-row gap-1 border-l-1 border-[#fff] px-2 text-sm" id="user-name">
                        <span>{loggedFirstName}</span>
                        <span>{loggedLastName}</span>
                      </div>
                      <span className={dropdownToggle ? 'rotate-180' : ''}>
                        <ChevronDownSvg _color="white" _width={14} _height={14} />
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu position-absolute"
                      style={{
                        left: 'auto',
                        marginTop: '34px',
                        borderRadius: '12px',
                        borderTop: '6px solid #4fa2b4',
                        boxShadow: '0px 17px 60px 0px rgba(51, 51, 51, 0.1)',
                        paddingRight: '0px',
                      }}
                    >
                      <li>
                        <a href="#/profile" className="dropdown-item">
                          Account
                        </a>
                        <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} className="dropdown-item">
                          Bookings
                        </a>
                        <hr />
                        <a href="#/terms-and-conditions" className="dropdown-item" target="_blank" rel="noreferrer">
                          Terms & Conditions
                        </a>
                        <a href="#/privacy-policy" className="dropdown-item" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                        <hr />
                        <a className="dropdown-item" href="/#/logout">
                          Logout
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  <GalagoButton
                    btn="primary"
                    size="sm"
                    className="me-2 w-100"
                    onClick={() => setShowSignUpDialog(true)}
                    label="Login or Sign Up"
                    style={{
                      margin: '0px',
                      fontSize: '16px',
                      borderRadius: '8px',
                      padding: '8px 0',
                      backgroundColor: color ? '#016372' : '',
                      border: color ? '1px solid #016372' : '',
                    }}
                  />
                )}
              </li>
            </ul>
          </Container>
        </nav>
      </div>

      <SignUpDialog show={showSignUpDialog} onHide={() => setShowSignUpDialog(false)} />
      <EmailDialog show={showEmailDialog} onHide={() => setShowEmailDialog(false)} />
      <PasswordDialog show={showPasswordDialog} onHide={() => setShowPasswordDialog(false)} />
      {/* <RegistrationDialog /> */}
      <EmailConfirmationDialog />
      <PendingVerificationDialog />
      <EmailSentDialog />
    </>
  );
};

export default NavBarTransparent;
