import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import { LocationFilledSvg } from '../../../resources/svg/LocationSvg';

type HotelLocationProps = {
  id: string;
  locationData: {
    address: string;
    coordinates: string;
  };
  facilitiesData: { [index: string]: any };
};

const HotelLocation = ({ id, locationData, facilitiesData }: HotelLocationProps) => {
  // const publicImageApi = ASSETS_API().public.images;
  const { address, coordinates } = locationData;
  const coordinatesArray = coordinates.split('|'); // split coordinates string into array

  let nearbyPlaces: string = '';
  if (facilitiesData.attractions) {
    Object.keys(facilitiesData.attractions).forEach((key: any, index) => {
      const places = facilitiesData.attractions[key].toString().split('<br />');
      for (let index = 0; index < places.length; index++) {
        // replace all p tags
        const place: string = places[index].replaceAll('</p>', '').replaceAll('<p>', '');
        // omit the first index
        if (index !== 0) {
          nearbyPlaces += `${place.substring(0, place.length - 1)},`;
        }
      }
    });
  }

  return (
    <section className="container">
      <section id={id} className="row justify-content-center mb-5">
        <div className="col-11 pt-5 border-t-1 border-[#d9d9d9]">
          <div className="">
            <div className="mb-4 satoshi-medium font-semibold textColor">Location</div>
            <div className="fs-6 mb-3">{address && address.trim()}</div>
            <div className="w-100 rounded-xl border-1 border-[#d9d9d9]">
              <MapContainer style={{ height: '500px', zIndex: '0', borderRadius: '12px' }} center={[Number(coordinatesArray[0]), Number(coordinatesArray[1])]} zoom={100} scrollWheelZoom={false}>
                <TileLayer attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[Number(coordinatesArray[0]), Number(coordinatesArray[1])]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}></Marker>
              </MapContainer>
            </div>
            {nearbyPlaces.length > 0 ? (
              <div className="w-100 fs-6 mt-4">
                <div className="mb-3 text-primary font-medium flex flex-row gap-2">
                  <span>Nearby Attractions</span>
                  <LocationFilledSvg _width={20} _height={20} />
                </div>
                {nearbyPlaces.substring(0, nearbyPlaces.length - 1)}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </section>
  );
};

export default HotelLocation;
