import React, { useEffect, useState } from 'react';
import { FloatingLabelFormControl } from '../../../components/FormControl';
import { FlightPassengersStore } from '../../../store/FlightPassengersStore';
import { CountryCodesSelectInput } from '../../../components/CountryCodesSelectInput';

const ContactDetails = () => {
  // const { isSwitchOn, toggleSwitch, isPassengerDetailsCheckboxTicked } = useInternationalBooking();
  const { selectedPassengers, appendContact } = FlightPassengersStore();

  // const [showCountryCodes, setShowCountryCodes] = useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState<Boolean>(false);
  const handleToggleSwitch = (e: any) => {
    setIsSwitchOn(e.target.checked);
  };

  useEffect(() => {
    appendContact({ ...[{ idd: '+63' }] });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // clear the contacts field
    appendContact({
      ...[
        {
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          mobile: '',
          idd: '+63',
        },
      ],
    });

    // eslint-disable-next-line
  }, [isSwitchOn]);

  const contactDetailChange = (e: any) => {
    const dataset = e.target.selectedOptions[0].dataset;
    appendContact([
      {
        firstName: dataset.firstname,
        lastName: dataset.lastname,
        middleName: dataset.middlename,
        idd: '+63',
      },
    ]);
  };

  return (
    <div className="w-[37.38rem] self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-right text-[1rem] text-text-colors-text-primary font-footnote">
      <div className="w-full flex flex-col py-2.5 items-start justify-start gap-2.5 relative">
        <div className="self-stretch relative leading-[2.56rem] text-[2.12rem] align-self-start textColor font-satoshi font-bold">Contact Details</div>

        <p className="text-left textColor font-medium font-footnote">Please provide your contact details so we can reach out regarding any booking or payment updates.</p>
      </div>

      <div className="text-[1rem] font-footnote self-stretch flex flex-col items-start justify-start gap-[1rem]">
        {/* checkbox */}
        <div className="d-flex align-items-end gap-[12px] py-[10px]">
          <div className="form-check form-switch">
            <input onClick={handleToggleSwitch} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
          </div>
          <div>
            <span className="font-regular text-secondary">Use Passenger Details</span>
          </div>
        </div>

        {/* use passenger switch state on */}
        {isSwitchOn && (
          <>
            <div className="w-full">
              <select className="form-select capitalize" aria-label=".form-select-sm example" onChange={contactDetailChange}>
                <option selected disabled>
                  Select Passenger
                </option>
                {selectedPassengers?.map((value: { firstName: string; middleName: string; lastName: string }, key) => {
                  return (
                    <React.Fragment key={key}>
                      <option data-firstName={value.firstName} data-lastName={value.lastName} data-middleName={value.middleName}>
                        {value.firstName} {value.middleName} {value.lastName}
                      </option>
                    </React.Fragment>
                  );
                })}
              </select>
            </div>
          </>
        )}

        {!isSwitchOn && (
          <>
            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ firstName: e.target.value }] });
                }}
                type={'text'}
                placeholder={'First Name'}
                label={'First Name *'}
                style={{ width: '100%' }}
                autoComplete="false"
              />
            </div>

            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ middleName: e.target.value }] });
                }}
                type={'text'}
                placeholder={'Middle Name (Optional)'}
                label={'Middle Name (Optional)'}
                style={{ width: '100%' }}
                autoComplete="false"
              />
            </div>

            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ lastName: e.target.value }] });
                }}
                type={'text'}
                placeholder={'Last Name'}
                label={'Last Name *'}
                style={{ width: '100%' }}
                autoComplete="false"
              />
            </div>
          </>
        )}

        <div className="w-full">
          <FloatingLabelFormControl
            onChange={(e) => {
              appendContact({ ...[{ email: e.target.value }] });
            }}
            type={'text'}
            placeholder={'Email Address'}
            label={'Email Address *'}
            style={{ width: '100%' }}
            autoComplete="false"
          />
        </div>

        <div className="flex flex-row items-end justify-start w-full gap-[0.88rem]">
          {/* <CountryAreaCodeDropdown
            optionalClassName="w-[120px]"
            onChange={(e: any) => {
              appendContact({ ...[{ idd: e.code }] });
            }}
            show={false}
            onHide={function (): void {
              throw new Error('Function not implemented.');
            }}
          /> */}
          <div className="w-[110px]">
            <CountryCodesSelectInput
              onChange={(e) => {
                appendContact({ ...[{ idd: e.target.value }] });
              }}
            />
          </div>

          <div className="col">
            <FloatingLabelFormControl
              onChange={(e) => {
                appendContact({ ...[{ mobile: e.target.value }] });
              }}
              type={'text'}
              placeholder={'Mobile Number'}
              label={'Mobile Number (Optional)'}
              autoComplete="false"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full">
        <hr className="w-full text-gray-200" />
      </div>
    </div>
  );
};

export default ContactDetails;
