import React, { useState } from 'react';
// import { api } from '../../../lib/axios-interceptors/api';
import { FlightBookingModalHooks } from '../../../hooks/FlightBookingModalHooks';
import { HTTP_CREATED } from '../../../utils/httpResponse.utils';
import { GalagoButton } from '../../../components/Button';
import { PayMongoHook } from '../../../hooks/PayMongoHook';
import { GallyWinkSvg } from '../../../resources/svg/GallySvg';

type BookingModalProps = {
  onClose?: () => void;
  totalAmount: number;
  preBookDetails: { [index: string]: any } | undefined;
  TransactionId: string;
  SelectedFlight: { [index: string]: any };
  Passengers: { [index: string]: any };
  Contacts: { [index: string]: any };
};

const BookingModal = ({ onClose, totalAmount, preBookDetails, Passengers, Contacts, TransactionId, SelectedFlight }: BookingModalProps) => {
  // const cookie = new Cookie();

  const transactionId = TransactionId;
  const { galagoBookFlight } = FlightBookingModalHooks();
  const { pay } = PayMongoHook();
  // const flightType = urlState?.state.flightDetails.type;

  let theTotalAmount = totalAmount;
  if (process.env.REACT_APP_BOOK_FLIGHT_TARGET_ENV === 'Test') {
    // this part is used only in test environment
    theTotalAmount = totalAmount * 56; // converted to PHP
  }

  theTotalAmount = Number((Number(theTotalAmount.toFixed(2)) * 100).toFixed(2));
  const [payNowClicked, setPayNowClicked] = useState(false);

  const onClickPayNow = async () => {
    // console.log(`Bearer ${token}`);
    // return;
    // let apiResponse: AxiosResponse;
    // let maxRedirects = 1;
    // setPayNowClicked(true);
    // do {
    //   try {
    //     apiResponse = await api.post('/payments/checkout-session', {
    //       description: transactionId,
    //       line_items: [
    //         {
    //           amount: theTotalAmount,
    //           currency: 'PHP',
    //           description: transactionId,
    //           images: null,
    //           name: 'Air Ticket',
    //           quantity: 1,
    //         },
    //       ],
    //       product: 'flights', // flights | hotels
    //       platform: 'web', // web | mobile
    //       referenceNumber: transactionId,
    //     });
    //   } catch (err: any) {
    //     // console.log('err: ', err);
    //     apiResponse = err.response;
    //     setPayNowClicked(false);
    //   }
    //   maxRedirects++;
    // } while (apiResponse.status > 299 && maxRedirects <= 5);

    // if (maxRedirects >= 5) {
    //   setPayNowClicked(false);
    //   alert('Error encountered in checkout session');
    //   return;
    // }

    const payResponse = await pay({
      setPayNowClicked: setPayNowClicked,
      description: transactionId,
      line_items: [
        {
          amount: theTotalAmount,
          currency: 'PHP',
          description: transactionId,
          images: null,
          name: 'Air Ticket',
          quantity: 1,
        },
      ],
      product: 'flights',
      platform: 'web',
      referenceNumber: transactionId,
      stage: window.location.origin,
    });

    const { checkout_url, payment_intent, reference_number } = payResponse as { checkout_url: string; payment_intent: { [index: string]: any }; reference_number: string };
    // booking
    const paymentIntentId: string = payment_intent?.id;
    const referenceId: string = reference_number;

    if (checkout_url && paymentIntentId && referenceId) {
      const flightBookResponse = (
        await galagoBookFlight({
          paymentIntentId,
          referenceId,
          theTotalAmount,
          TransactionId: transactionId,
          Flight: SelectedFlight,
          Contacts: Contacts,
          Passengers: Passengers,
        })
      ).data;

      if (flightBookResponse.statusCode !== HTTP_CREATED()) {
        alert(flightBookResponse.message);
        // jump to the index page
        window.location.replace('/');
      }

      // if status code is 201 created proceed to paymongo page
      if (flightBookResponse.statusCode === HTTP_CREATED()) {
        // jump to the paymongo page
        window.location.replace(checkout_url);
      }
    }
  };

  return (
    <section className="absolute top-[calc(50%_-_205px)] left-[calc(50%_-_205px)] w-[25.63rem] h-[25.63rem] text-center text-[0.88rem] text-text-colors-text-primary font-footnote">
      <div className="absolute top-[calc(50%_-_142px)] left-[calc(50%_-_166px)] w-[20.81rem] h-[17.19rem]">
        <div className="absolute top-[calc(50%_-_137.5px)] left-[calc(50%_-_166.5px)] w-[20.81rem] flex flex-col items-center justify-center gap-[1.5rem]">
          <div className="self-stretch flex flex-col items-center justify-center gap-[0.75rem]">
            <div className="self-stretch flex flex-col items-center justify-center gap-[1.26rem]">
              {/* <img className="relative w-[6.18rem] h-[4.36rem] overflow-hidden shrink-0 object-cover" alt="" src="/svg/layer-1.svg" /> */}
              <GallyWinkSvg />
              <div className="relative self-stretch font-medium font-footnote">Redirecting you to our Payment Page</div>
            </div>
            <div className="self-stretch relative text-[0.81rem] leading-[1.13rem] font-medium text-text-colors-text-secondary font-footnote">
              Almost there! Double-check your booking details. Click Pay Now to proceed or Cancel to make any changes.
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-[0.63rem] text-left text-[0.81rem] text-color-shades-white-800 w-75">
            <GalagoButton
              onClick={onClickPayNow}
              disabled={transactionId === '' || payNowClicked ? true : false}
              label="Pay Now"
              // btn="secondary"
              // _className={`${transactionId === '' || payNowClicked ? 'btn-secondary' : 'btn-primary'} btn-sm`}
              size="sm"
              btn={transactionId === '' || payNowClicked ? 'secondary' : 'primary'}
              style={{ boxShadow: ' 1.95px 1.95px 2.6px 0px #00000026' }}
            />
            <GalagoButton onClick={onClose} label="Cancel" btn="light" size="sm" />;
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingModal;
