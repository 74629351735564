import BookingSummary from './BookingSummary';
import BookingDetails from './BookingDetails';
import React from 'react';
import PassengerDetails from './PassengerDetails';
import ContactDetails from './ContactDetails';
import AgreeToConditions from './AgreeToConditions';
import { useLocation } from 'react-router';
import { MystiflyFlightSegmentHook } from '../../../hooks/MystiflyFlightSegmentHook';
import { useSearchParams } from 'react-router-dom';
// import {FlightResultsHook} from '../../hooks/FlightResultsHook'

// type FlightFormProps = {};

const FlightForm = () => {
  const [searchParams] = useSearchParams();
  const urlState = useLocation();

  const transactionId: string = urlState.state !== null ? urlState.state.transactionId : searchParams.get('id');

  const selectedFlight: { [index: string]: any } = urlState.state.selectedFlightDetails;
  // console.log(selectedFlight);
  const conversationId: string = selectedFlight.conversationId;
  const flight: { [index: string]: any } = selectedFlight.flight;
  const passengers: { [index: string]: any } = selectedFlight.passengers;
  // console.log({ passengers });
  const flightType: 'one-way' | 'round-trip' = selectedFlight.type;
  const adults = passengers?.adults;
  const minors = passengers?.minors;
  const children = passengers?.children;
  const infants = passengers?.infants;
  const passengerCount = adults + minors + children + infants;

  const { segments, itinerary } = MystiflyFlightSegmentHook();
  const { departureFlightSegmentDetails, departureAirline, returnFlightSegmentDetails, returnAirline } = segments({ FlightType: flightType, FlightDetails: flight });
  const isDomestic = departureFlightSegmentDetails?.isDomesticFlight;
  // setIsDomesticFlight(isDomestic);

  const { departureItinerary, returnItinerary } = itinerary(flightType, flight);

  return (
    <div className="relative self-stretch flex flex-col py-[64px] px-[104px] items-start justify-start text-[2.13rem] text-right text-success-success-200 font-footnote">
      <div className="self-stretch flex flex-row items-start justify-center flex-nowrap gap-24 text-right text-[0.81rem] text-black-black-200 font-footnote">
        {/* left body */}
        <div className="flex flex-col items-start justify-start gap-[3.75rem] text-center text-[1rem] text-primary-primary-100 font-footnote">
          <BookingDetails
            FlightType={flightType}
            Flight={flight}
            DepartureFlightSegmentDetails={departureFlightSegmentDetails}
            ReturnFlightSegmentDetails={returnFlightSegmentDetails}
            DepartureAirline={departureAirline}
            ReturnAirline={returnAirline}
            departureItinerary={departureItinerary}
            returnItinerary={returnItinerary}
          />
          <PassengerDetails PassengerCount={passengerCount} Passengers={passengers} Flight={flight} FlightType={flightType} IsDomestic={isDomestic} />
          <ContactDetails />
          <AgreeToConditions IsDomestic={isDomestic} Flight={selectedFlight} passengerCount={passengerCount} TransactionId={transactionId} ConversationId={conversationId} />
        </div>

        <BookingSummary
          FlightType={flightType}
          Flight={flight}
          DepartureFlightSegmentDetails={departureFlightSegmentDetails}
          ReturnFlightSegmentDetails={returnFlightSegmentDetails}
          DepartureAirline={departureAirline}
          ReturnAirline={returnAirline}
          PassengerCount={passengerCount}
          AdultCount={adults}
          MinorCount={minors}
          ChildrenCount={children}
          InfantCount={infants}
        />
      </div>
    </div>
  );
};

export default FlightForm;
