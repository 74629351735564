import React from 'react';
import { Modal } from 'react-bootstrap';
import { ASSETS_API } from '../utils/apiRoutes';
import { AnimatedLogoLoadingStore } from '../store/AnimatedLogoLoadingStore';

interface dialogInterface {
  show?: boolean;
  onHide?: () => void;
  loadingText?: string;
}

export const AnimatedGalagoLoading = ({ show, onHide, loadingText }: dialogInterface) => {
  const imagesUrl = ASSETS_API().public.gif;
  const { animatedLogoText, showAnimatedLogoLoading, setShowAnimatedLogoLoading } = AnimatedLogoLoadingStore();

  return (
    <Modal
      show={show ?? showAnimatedLogoLoading}
      onHide={() => (show ? !show : setShowAnimatedLogoLoading(false))}
      className="galago-modal-md"
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="p-0"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={`${imagesUrl}/gally.gif`} alt="" style={{ width: '50%', display: 'flex' }} />
        </div>
        <p style={{ textAlign: 'center', fontSize: '14px' }}>{show ? loadingText : animatedLogoText}</p>
      </Modal.Body>
    </Modal>
  );
};
