import React from 'react';
import Offers from './Offers';
import { CheckSvg } from '../../../resources/svg/CheckSvg';

type HotelFacilitiesRcolProps = { hotelFacilities: string[] };

const HotelFacilitiesRcol = ({ hotelFacilities }: HotelFacilitiesRcolProps) => {
  let hotelFacilityHolder: string[] = [];
  const offers: React.ReactElement[] = [];

  if (hotelFacilities) {
    for (let [index, hotelFacility] of hotelFacilities.entries()) {
      if (index > 14) break;
      hotelFacilityHolder.push(hotelFacility);
      if ((index + 1) % 5 === 0) {
        offers.push(<Offers key={index} hotelFacilityHolder={hotelFacilityHolder} />);
        hotelFacilityHolder = [];
      }
    }
  }

  return (
    <>
      <div className="py-[40px]">
        <div className="relative font-semibold flex items-center w-[28.38rem] mb-[20px]">This property offers</div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[2rem] text-[0.81rem]">
          {/* <div className="flex flex-row items-start self-stretch justify-between">{offers}</div> */}
          <div className="flex flex-row flex-wrap align-items-center">
            {hotelFacilities.map((facility, index) => {
              return (
                <div className="flex flex-row align-items-center mb-2.5" key={index} style={{ width: '33.33%' }}>
                  <div className="p-2">
                    <CheckSvg _color="#016E7F" _width={24} _height={24} />
                  </div>
                  <div className="text-medium font-medium">{facility}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelFacilitiesRcol;
