import React from 'react';

interface TitleSelectInputProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  important?: false;
  passengerType?: string;
  props?: any;
}

export const TitleSelectInput = ({ important, passengerType, ...props }: TitleSelectInputProps) => {
  const passenger = passengerType?.toLowerCase();
  return (
    <div className="form-floating w-full">
      <select className="form-select w-full" aria-label="" {...props}>
        <option value="" selected disabled></option>
        {passenger === 'adult' || passenger === 'minor' ? (
          <>
            <option value="mister">Mr</option>
            <option value="miss">Ms</option>
          </>
        ) : null}

        {passenger === 'child' || passenger === 'infant' ? (
          <>
            <option value="mistress">Mistress</option>
            <option value="master">Master</option>
          </>
        ) : null}
      </select>
      <label htmlFor="floatingSelectGrid">Title{important ? ' *' : ''}</label>
    </div>
  );
};
