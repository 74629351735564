import React from 'react';
import '../../resources/css/hotel-booking-summary.scss';
import { formatPriceNoRound } from '../../utils/data.utils';
import { NavigateFunction } from 'react-router';
// import { StringToBoolean } from 'class-variance-authority/dist/types';
// import { Check, ChevronDown } from 'lucide-react';
import { CheckSvg } from '../../resources/svg/CheckSvg';
import { ChevronDownSvg } from '../../resources/svg/ChevronDownSvg';

type HotelBookingSummaryProps = {
  optionalClassName?: string;
  hotelInfo: { [index: string]: any };
  selectedRooms: { [index: string]: any };
  nights: number;
  facilitiesData?: { [index: string]: any };
  searchCriteria: any;
  navigate: NavigateFunction;
  setSelectedHotelRooms: (param: { [index: string]: any }, reset: boolean) => void;
  preBookingTBOResponse?: { [index: string]: any };
};

const HotelBookingSummary = ({ searchCriteria, optionalClassName, hotelInfo, selectedRooms, nights, preBookingTBOResponse, navigate, setSelectedHotelRooms }: HotelBookingSummaryProps) => {
  const bookingDetails = hotelInfo?.bookingDetails.split('|');
  const room = bookingDetails[1].split(',')[0];

  let totalRoomPrice = 0;
  // let totalDayRates = 0;
  let totalFees = 0;
  let roominclusions: String = '';

  // eslint-disable-next-line
  preBookingTBOResponse?.map((value: { [index: string]: any }, key: React.Key) => {
    // eslint-disable-next-line
    value?.Rooms?.map((room: { [index: string]: any }, roomKey: React.Key) => {
      totalRoomPrice += room.TotalFare;
      totalFees += room.TotalTax;
      roominclusions = room.Inclusion;
    });
  });

  const firstImage = hotelInfo.images[0] === undefined ? '/img/missing-img.png' : hotelInfo.images[0];
  const secondImage = hotelInfo.images[1] === undefined ? '/img/missing-img.png' : hotelInfo.images[1];
  const thirdImage = hotelInfo.images[2] === undefined ? '/img/missing-img.png' : hotelInfo.images[2];

  const [isTotalRoomPriceClicked, setIsTotalRoomPriceClicked] = React.useState(false);

  return (
    <div id="sticky-hotel-booking-details" className="w-100 mb-10">
      <div className="sticky-hotel-title mb-8">
        <h3 className="m-0 font-semibold">{hotelInfo?.propertyName}</h3>
      </div>
      <div className="sticky-hotel-image-holder">
        <div>
          <div style={{ backgroundImage: `url(${firstImage})`, backgroundPosition: 'center' }}></div>
        </div>
        <div>
          <div style={{ backgroundImage: `url(${secondImage})`, backgroundPosition: 'center' }}></div>
          <div style={{ backgroundImage: `url(${thirdImage})`, backgroundPosition: 'center' }}></div>
        </div>
      </div>
      <span
        className="mt-3 text-primary cursor-pointer underline underline-offset-2 anchor-text"
        onClick={() => {
          setSelectedHotelRooms([], true);
          navigate(-1);
        }}
      >
        Change your selection
      </span>
      <h4 className="mt-4 textColor mb-0 generalsans-medium text-xl">Reservation Details</h4>
      <div className="flex flex-col gap-1 w-100 mt-3">
        <span className="text-secondary text-sm generalsans-regular">Stay Dates</span>
        <div className="flex flex-row gap-2 textColor generalsans-medium">
          <span>{bookingDetails[0]}</span>
          <span>
            ({nights} night{nights > 1 ? 's' : null})
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 mt-4 w-full">
        <span className="text-secondary text-sm generalsans-regular">Selected Room{selectedRooms.length > 1 ? 's' : null}</span>
        <span className="generalsans-regular textColor">
          {selectedRooms.map((value: { [index: string]: any }, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                <div className="mb-3 flex flex-column gap-2">
                  <span className="textColor text-base generalsans-medium">{value.Name[0]}</span>
                  <div className="flex flex-column gap-1 pb-3 border-b-1 border-[#E7EAEB]">
                    <div className="flex flex-row gap-1 items-center">
                      <CheckSvg _width={20} _height={20} />
                      <span className="textColor text-base generalsans-regular">{roominclusions}</span>
                    </div>

                    <div className="flex flex-row gap-1 items-center">
                      <CheckSvg _width={20} _height={20} />
                      <span className="textColor text-base generalsans-regular">
                        Max of {searchCriteria.adults} adult{searchCriteria.adults > 1 ? 's' : null}
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </span>
      </div>

      <div className="sticky-hotel-price-breakdown mb-4">
        <h4 className="mt-2 textColor generalsans-medium text-xl mb-0">Price Overview</h4>

        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-column gap-1 cursor-pointer" onClick={() => setIsTotalRoomPriceClicked(!isTotalRoomPriceClicked)}>
            <div className="flex flex-row gap-1.5 items-center">
              <span className="textColor text-base generalsans-medium">Total Room Price</span>
              <span className={isTotalRoomPriceClicked ? 'rotate-180' : ''}>{parseInt(room.match(/\d+/)[0]) > 1 ? <ChevronDownSvg _width={14} _height={14} _color="#ADADAD" /> : null}</span>
            </div>
            <span className="textColor text-sm generalsans-regular">
              {room} {room > 1 ? 's' : ' '} x {nights} night{nights > 1 ? 's' : ''}
            </span>
          </div>
          <span className="textColor generalsans-medium text-base">PHP {formatPriceNoRound(totalRoomPrice - totalFees)}</span>
        </div>

        {isTotalRoomPriceClicked && (
          <div className="flex flex-column w-full">
            {selectedRooms.map((value: { [index: string]: any }, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  <div className="flex flex-row justify-between text-secondary generalsans-regular text-base mb-1">
                    <span>{value.Name[0]}</span>
                    <span>PHP {formatPriceNoRound(value.TotalFare)}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        )}

        <div className="flex flex-row w-full justify-between textColor generalsans-medium">
          <span>Taxes and Fees</span>
          <span>PHP {formatPriceNoRound(totalFees)}</span>
        </div>

        {/* GalaGO! Discount: Hidden */}
        <div className="d-none flex flex-row w-full justify-between generalsans-medium text-[#BD0A00]">
          <span>GalaGO! Discount</span>
          <span>PHP {formatPriceNoRound(totalRoomPrice)}</span>
        </div>
      </div>

      <div className="sticky-hotel-total-price">
        <div>
          {/* <span>We are holding your price </span>
            <span>00:00:00</span> */}
        </div>
        <span className="generalsans-medium text-xl text-primary">Total Price</span>
        <div className="flex justify-center items-center bg-[#016e7f33] textColor text-2xl w-full py-3.5 font-semibold rounded">
          <span>PHP {formatPriceNoRound(totalRoomPrice)}</span>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingSummary;
