import React, { useState } from 'react';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { CheckSvg } from '../../../resources/svg/CheckSvg';
import { GalagoButton } from '../../../components/Button';
import '../../../resources/css/popup.scss';
import PopupComp from '../../../components/ui/PopupComp';
import moment from 'moment';
import '../../../resources/css/gallery.scss';
// import { useSearchParams } from 'react-router-dom';

type HotelCardContainerProps = {
  roomImage?: string;
  room: { [index: string]: any };
  nights: number;
  hotelInfo: { [index: string]: any };
  facilitiesData: { [index: string]: any };
  Currency?: string;
  setSelectedHotelRooms: (arg: { [index: string]: any }) => void;
  isSelected: boolean;
  disableButton: boolean;
};

const HotelCardContainer = ({ Currency, roomImage, room, nights, hotelInfo, facilitiesData, setSelectedHotelRooms, isSelected, disableButton }: HotelCardContainerProps) => {
  const roomName = room.Name[0].trim().replaceAll(',', ' ');
  const { CancelPolicies, Inclusion } = room;
  const roomPriceToFloat = room.TotalFare / nights;
  const roomPricewithTax = room.TotalFare / nights + room.TotalTax;
  // eslint-disable-next-line
  const [isHidden, setIsHidden] = useState(true);

  // reverse the cancellationPolicy object
  const cancellationPolicyReverse: { [index: string]: any } = [];
  for (let j = CancelPolicies.length - 1; j >= 0; j--) {
    cancellationPolicyReverse.push(CancelPolicies[j]);
  }

  return (
    <section className="col-11 mb-5 position-relative">
      <div className="">
        <h5 className="generalsans-medium mb-3 text-lg">{roomName}</h5>

        <div className="rounded-xl bg-teal row py-8 px-12  m-0">
          <div className=" flex flex-row justify-between gap-10 p-0">
            <div className="flex pt-4  flex-col col items-start justify-start gap-4">
              <span className="relative satoshi-medium text-xl">Room Inclusions</span>
              <div className="">
                <div className="flex flex-col items-start justify-start gap-2.5 fs-6">
                  <>
                    {/* eslint-disable-next-line */}
                    {cancellationPolicyReverse.map((value: { FromDate: string | undefined; ChargeType: string | undefined; CancellationCharge: number | undefined }, key: number) => {
                      // const date = value.FromDate?.split(' ')[0].split('-');
                      // const fromDate = value.FromDate ? moment(new Date(`${date?.[2]}-${date?.[1]}-${date?.[0]}`)).format('MMMM DD, YYYY') : null;

                      // get the next date for the next policy
                      let nextDateArray: string[] | undefined = [];
                      // eslint-disable-next-line
                      [cancellationPolicyReverse[key + 1]].map((policy: { [index: string]: any }, _key: number) => {
                        nextDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
                      });
                      let lastDateArray: string[] | undefined = [];
                      // eslint-disable-next-line
                      [cancellationPolicyReverse[cancellationPolicyReverse.length - 1]].map((policy: { [index: string]: any }, _key: number) => {
                        lastDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
                      });

                      const nextDate = nextDateArray !== undefined ? moment(`${nextDateArray[0]}-${nextDateArray[1]}-${Number(nextDateArray[2]) - 1}`).format('MMMM DD, YYYY') : undefined;
                      const lastDate = lastDateArray !== undefined ? moment(`${lastDateArray[0]}-${lastDateArray[1]}-${lastDateArray[2]}`).format('MMMM DD, YYYY') : undefined;
                      if (value.CancellationCharge === 0) {
                        // if nextDate is in the past then return false
                        if (nextDate !== undefined && moment(nextDate).isBefore(moment())) {
                          return null;
                        }
                        return (
                          <div className="flex flex-row gap-1 justify-start w-100" key={key}>
                            <CheckSvg _width={25} _height={25} />
                            <div className="w-100">
                              {nextDate !== undefined ? `Free cancellation until ${nextDate}` : null}
                              {lastDate === undefined ? `This room is 100% refundable` : null}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>

                  <div className="flex flex-row gap-2 items-center justify-start capitalize">
                    <CheckSvg _width={25} _height={25} />
                    <div className="font-medium w-full text-nowrap">{Inclusion?.toLowerCase().replaceAll(',', ', ')}</div>
                  </div>
                  <div className="flex flex-row gap-2 items-center justify-start capitalize">
                    <CheckSvg _width={25} _height={25} />
                    <div className="font-medium">Adults {room.Adults}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex pt-4 px-5 border-x-1 border-[#D9D9D9] flex-col col-3 items-start justify-start gap-4">
              <span className="relative satoshi-medium text-xl">Sleeps</span>
              <div className="flex flex-col items-start justify-start text-[1.06rem] font-footnote">
                <div className="flex flex-col items-start justify-start">
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative flex items-center text-base shrink-0 font-medium">
                      Max {room.Adults + room.Children} guest{room.Adults > 1 ? 's' : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex pt-4 flex-col col-4 justify-between gap-4">
              <span className="relative satoshi-medium text-xl text-end">Price per night</span>
              <div className="flex flex-col align-items-end items-start justify-start text-[1rem] font-footnote">
                <div className="flex flex-col items-end justify-start gap-4 w-100">
                  <div className="flex flex-column gap-1">
                    <div className="flex flex-row items-center justify-start gap-[0.75rem] text-[1.5rem] text-gray-900">
                      <div className="relative ms-auto font-semibold flex  shrink-0 font-footnote textColor">
                        {Currency} {formatPriceNoRound(roomPriceToFloat)}
                      </div>
                    </div>
                    <span className="text-secondary font-medium">
                      {Currency} {formatPriceNoRound(roomPricewithTax)} with Taxes and Fees
                    </span>
                  </div>

                  <span className="w-3/5">
                    <GalagoButton
                      onClick={() => setSelectedHotelRooms(room)}
                      disabled={disableButton}
                      style={{ background: '#D0F0EF', color: '#016E7F' }}
                      label={isSelected ? 'Selected Room' : 'Select Room'}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isHidden ? (
        <div style={{ display: 'none' }}>
          <PopupComp />
        </div>
      ) : (
        <div style={{ display: 'flex', zIndex: 999 }}>
          <PopupComp />
        </div>
      )}
    </section>
  );
};

export default HotelCardContainer;
// Remove the unused 'userRef' function
// function userRef(arg0: null) {
//   throw new Error('Function not implemented.');
// }
