import React from 'react';
import { hotelStore } from '../../../store/HotelStore';
import AllHotelPhotosModalGallery from './AllHotelPhotosModalGallery';
import AllHotelPhotosModalNavbar from './AllHotelPhotosModalNavbar';
import AllHotelPhotosModalRightSidebar from './AllHotelPhotosModalRightSidebar';
import AllHotelPhotosModalSlideShow from './AllHotelPhotosModalSlideShow';
// import { GalagoButton } from '../../../components/Button';
import { CloseCircle } from '../../../resources/svg/CloseCircleSvg';

type AllHotelPhotosModalProps = { onClose?: () => void; images: string[]; facilities: string[] };

const AllHotelPhotosModal = ({ onClose, images, facilities }: AllHotelPhotosModalProps) => {
  const hotelRoomImages = images;

  const { slideShow } = hotelStore();

  return (
    <section className="relative">
      <AllHotelPhotosModalNavbar />
      <AllHotelPhotosModalRightSidebar facilities={facilities} />

      <button onClick={onClose} className="cursor-pointer absolute left-[73rem] top-[22px]">
        <CloseCircle _color="#016E7F" />
      </button>

      {slideShow && <AllHotelPhotosModalGallery images={hotelRoomImages} />}
      {!slideShow && <AllHotelPhotosModalSlideShow images={hotelRoomImages} />}
    </section>
  );
};

export default AllHotelPhotosModal;
