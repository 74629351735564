import React from 'react';
import '../../../resources/css/search-filter.scss';
import { GalagoButton } from '../../../components/Button';
import SliderFilter from '../../../components/ui/slider-filter';
import moment from 'moment';
import FlightFilterCheckbox from '../../../components/ui/checkbox-filter';

type FlightFiltersRoundTripType = {
  selectedReturnDepartureTimeRange: { start: string; end: string };
  setSelectedReturnDepartureTimeRange: (value: { start: string; end: string }) => void;
  setIsFilterChanged: (value: boolean) => void;
  selectedReturnArrivalTimeRange: { start: string; end: string };
  setSelectedReturnArrivalTimeRange: (value: { start: string; end: string }) => void;
  selectedReturnStops: string[];
  setSelectedReturnStops: (value: string[]) => void;
  setSelectedReturnCabinAndCheckInBaggage: (value: boolean) => void;
  selectedReturnCabinAndCheckInBaggage: boolean;
  returnAirlineList: { [index: string]: any };
  showAllAirlines: boolean;
  selectedReturnAirlines: string[];
  handleShowAirlines: () => void;
  setSelectedReturnAirlines: (value: string[]) => void;
  checkboxOneStopRef: React.RefObject<HTMLInputElement>;
  checkboxDirectRef: React.RefObject<HTMLInputElement>;
  checkboxManyRef: React.RefObject<HTMLInputElement>;
  checkboxCabinAndCheckInBaggageRef: React.RefObject<HTMLInputElement>;
  airlinesDivHolderRef: React.RefObject<HTMLDivElement>;
};

export default function FlightFiltersRoundTrip({
  selectedReturnDepartureTimeRange,
  setSelectedReturnDepartureTimeRange,
  setIsFilterChanged,
  selectedReturnArrivalTimeRange,
  setSelectedReturnArrivalTimeRange,
  selectedReturnStops,
  setSelectedReturnStops,
  setSelectedReturnCabinAndCheckInBaggage,
  selectedReturnCabinAndCheckInBaggage,
  returnAirlineList,
  showAllAirlines,
  selectedReturnAirlines,
  handleShowAirlines,
  setSelectedReturnAirlines,
  checkboxOneStopRef,
  checkboxDirectRef,
  checkboxManyRef,
  checkboxCabinAndCheckInBaggageRef,
  airlinesDivHolderRef,
}: FlightFiltersRoundTripType) {
  return (
    <>
      <div className="time-container">
        <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Time (Local Time)</p>
        <p className="text-black-black-200 generalsans-medium">Departure time</p>
        <p className="text-base generalsans-medium">
          <span>{selectedReturnDepartureTimeRange.start}</span> - <span>{selectedReturnDepartureTimeRange.end}</span>
        </p>

        <div className="time-slider-container mt-4 ms-1 me-2">
          <SliderFilter
            min={0}
            max={24}
            step={0.5}
            tooltip={false}
            defaultValue={[0, 24]}
            constraint={([start, end]: number[]): boolean => {
              const minSteps = 2;
              return start < end && end - start >= minSteps;
            }}
            onChange={(newValue: number[]) => {
              const newTime = {
                start: moment()
                  .hour(Math.floor(newValue[0]))
                  .minute(newValue[0] % 1 === 0.5 ? 30 : 0)
                  .format('HH:mm'),
                end:
                  newValue[1] === 24
                    ? '23:59'
                    : moment()
                        .hour(Math.floor(newValue[1]))
                        .minute(newValue[1] % 1 === 0.5 ? 30 : 0)
                        .format('HH:mm'),
              };

              setSelectedReturnDepartureTimeRange(newTime);
              setIsFilterChanged(true);
            }}
          />

          <div className="time-slider-label flex justify-between mt-3">
            <p className="font-semibold">00:00</p>
            <p className="font-medium">06:00</p>
            <p className="font-semibold">12:00</p>
            <p className="font-medium">18:00</p>
            <p className="font-medium">24:00</p>
          </div>
        </div>
        <p className="text-black-black-200 generalsans-medium">Arrival time</p>
        <p className="text-base generalsans-medium">
          <span>{selectedReturnArrivalTimeRange.start}</span> - <span>{selectedReturnArrivalTimeRange.end}</span>
        </p>

        <div className="time-slider-container mt-4 ms-1 me-2">
          <SliderFilter
            min={0}
            max={24}
            step={0.5}
            tooltip={false}
            defaultValue={[0, 24]}
            constraint={([start, end]) => {
              const minSteps = 2;
              return start < end && end - start >= minSteps;
            }}
            onChange={(newValue: number[]) => {
              const newTime = {
                start: moment()
                  .hour(Math.floor(newValue[0]))
                  .minute(newValue[0] % 1 === 0.5 ? 30 : 0)
                  .format('HH:mm'),
                end:
                  newValue[1] === 24
                    ? '23:59'
                    : moment()
                        .hour(Math.floor(newValue[1]))
                        .minute(newValue[1] % 1 === 0.5 ? 30 : 0)
                        .format('HH:mm'),
              };
              setSelectedReturnArrivalTimeRange(newTime);
              setIsFilterChanged(true);
            }}
          />
          <div className="time-slider-label flex justify-between mt-3">
            <p className="font-semibold">00:00</p>
            <p className="font-medium">06:00</p>
            <p className="font-semibold">12:00</p>
            <p className="font-medium">18:00</p>
            <p className="font-medium">24:00</p>
          </div>
        </div>
      </div>

      <div className="stops-container py-3">
        <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Stops</p>

        <div className="form-check">
          <label className="form-check-label pt-1 ml-2" htmlFor="stop1">
            Direct
          </label>
          <input
            ref={checkboxDirectRef}
            className="form-check-input custom-checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // if this checkbox is unchecked, remove "direct" from selectedStops
              if (e.currentTarget.checked === false) {
                selectedReturnStops.splice(selectedReturnStops.indexOf('direct'), 1);
              } else {
                setSelectedReturnStops(['direct']);
              }
              setIsFilterChanged(true);
            }}
            defaultChecked={selectedReturnStops.includes('direct')}
            type="checkbox"
            defaultValue="direct"
            id="stop1"
          />
        </div>

        <div className="form-check">
          <label className="form-check-label pt-1 ml-2" htmlFor="stop2">
            1 Stop
          </label>
          <input
            ref={checkboxOneStopRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.checked === false) {
                selectedReturnStops.splice(selectedReturnStops.indexOf('1'), 1);
              } else {
                setSelectedReturnStops(['1']);
              }
              setIsFilterChanged(true);
            }}
            defaultChecked={selectedReturnStops.includes('1')}
            className="form-check-input custom-checkbox"
            type="checkbox"
            defaultValue="1"
            id="stop2"
          />
        </div>

        <div className="form-check">
          <label className="form-check-label pt-1 ml-2" htmlFor="stop3">
            2+ Stops
          </label>
          <input
            ref={checkboxManyRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.checked === false) {
                selectedReturnStops.splice(selectedReturnStops.indexOf('many'), 1);
              } else {
                setSelectedReturnStops(['many']);
              }
              setIsFilterChanged(true);
            }}
            defaultChecked={selectedReturnStops.includes('many')}
            defaultValue="many"
            className="form-check-input custom-checkbox"
            type="checkbox"
            id="stop3"
          />
        </div>
      </div>

      <div className="baggage-container py-3">
        <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Baggage</p>
        <div className="form-check">
          <label className="form-check-label pt-1 ml-2" htmlFor="baggage">
            Cabin and Check-in Baggage
          </label>
          <input
            ref={checkboxCabinAndCheckInBaggageRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // setSelectCabinAndCheckInBaggage(e.currentTarget.checked);
              if (e.currentTarget.checked === false) {
                setSelectedReturnCabinAndCheckInBaggage(false);
              } else {
                setSelectedReturnCabinAndCheckInBaggage(true);
              }
              setIsFilterChanged(true);
            }}
            defaultChecked={selectedReturnCabinAndCheckInBaggage}
            // defaultValue={selectedCabinAndCheckInBaggage}
            className="form-check-input custom-checkbox"
            type="checkbox"
            id="baggage"
          />
        </div>
      </div>

      <div className="airlines-container py-3" ref={airlinesDivHolderRef}>
        <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Airlines</p>
        {returnAirlineList.map((airline: { [index: string]: any }, index: React.Key) => {
          // loop airlineList and display only five airlines first if the user clicks show all airlines, display all
          if (Number(index) > 4 && showAllAirlines === false) return null;
          return (
            <FlightFilterCheckbox
              key={index}
              id={`airlines${airline.airlineCode}`}
              label={airline.airlineName}
              defaultValue={airline.airlineCode}
              // checked={checkedState[`airlines${airline.airlineCode}`]}
              defaultChecked={selectedReturnAirlines.includes(airline.airlineCode)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked;
                const airlineValue = event.target.value;
                setIsFilterChanged(true);

                // if the checkbox is unchecked, check if the airline is in the selectedAirlines then remove it
                if (!isChecked) {
                  selectedReturnAirlines.splice(selectedReturnAirlines.indexOf(airlineValue), 1);
                  return;
                }
                // if airline value is not in the selectedAirlines, add it
                if (!selectedReturnAirlines.includes(airlineValue) && isChecked) {
                  setSelectedReturnAirlines([...selectedReturnAirlines, airlineValue]);
                }
              }}
              // description={'Price starts at PHP 0.00'}
            />
          );
        })}

        {returnAirlineList.length > 5 && (
          <GalagoButton label={showAllAirlines ? 'Show only 5 airlines' : 'Show all airlines'} btn="link" className="!justify-start !px-0 w-fit" onClick={handleShowAirlines} />
        )}
      </div>
    </>
  );
}
