import React from 'react';
import { useInternationalBooking } from '../../store/InternationalStore';
import { ASSETS_API } from '../../utils/apiRoutes';

const BookingNavbar = () => {
  const publicImageApi = ASSETS_API().public.images;
  const { toggleLogin } = useInternationalBooking();

  return (
    <nav className="bg-color-shades-white-800 shadow-[0px_1px_2px_rgba(60,_64,_67,_0.3),_0px_2px_6px_2px_rgba(60,_64,_67,_0.15)] w-full h-[6.25rem] overflow-hidden shrink-0 flex flex-row py-0 px-[104px] box-border items-center justify-between fixed top-0 z-1">
      <img className="relative w-[13.75rem] h-[2.45rem] overflow-hidden shrink-0 " alt="" src="/svg/galago-logo-1.svg" />

      <div onClick={toggleLogin} className="flex flex-row items-center justify-center cursor-pointer">
        <div className="flex flex-row items-center justify-start gap-[0.88rem]">
          <img className="relative rounded-[50%] w-[2rem] h-[2rem] object-cover" alt="" src={`${publicImageApi}/ellipse@2x.png`} />
          <div className="flex flex-row items-center justify-start gap-[0.25rem]">
            <img className="relative w-[0.06rem] h-[1.5rem]" alt="" src="/svg/vector-1.svg" />
            <div className="relative font-medium">Christian Mae</div>
            <img className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0" alt="" src="/svg/icon.svg" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default BookingNavbar;
