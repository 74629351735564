import React from "react";
import { Gally404 } from "../../resources/svg/Gally404";
import { NavBar } from "../../components/NavBar";
import Footer from "../../components/Footer";
import { GalagoButton } from "../../components/Button";
// import { ArrowLongRight } from "../../resources/svg/ArrowLongRightSvg";

export const NotFound = () => {
  return (
    <div className="flex flex-col">
      <NavBar />
      <div className="flex flex-col gap-[34px] py-[140px] items-center">
        <div className="flex flex-col items-center justify-center gap-10 ">
          <Gally404 _height={160} _width={200} />
          <div className="flex flex-col gap-y-1.5 items-center">
            <span className="text-2xl satoshi-bold textColor">Uh oh, something’s missing!</span>
            <p className="m-0 p-0 text-secondary text-base generalsans-medium">We cannot seem to find the page you are looking for. Let us find a better destination for you to go</p>
          </div>
        </div>
        <GalagoButton label="Go Back Home" btn="primary" size="lg" className="w-[200px]" onClick={() => (window.location.href = '/')} />
      </div>

      <Footer />
    </div>
  );  
};
