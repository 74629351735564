import { hotelLocation, searchAirport } from '../utils/apiRoutes';
import axios, { AxiosResponse } from 'axios';
import { FlightResultsHook } from './FlightResultsHook';
import { useState } from 'react';
import { AuthenticationHooks } from './AuthenticationHooks';
import { FlightFiltersStoreOneWay } from '../store/FlightFiltersStoreOneWay';
// import { FlightFilterStoreRoundTrip } from '../store/FlightFiltersStoreRoundTrip';

export const SearchPageHook = () => {
  const { flightCabinType } = FlightResultsHook();
  const { refreshToken } = AuthenticationHooks();
  const { setFilterKey, setAirlineList, setPriceRange, setSelectedPriceRange } = FlightFiltersStoreOneWay();
  // const { setReturnAirlineList, setReturnPriceRange, setSelectedReturnPriceRange } = FlightFilterStoreRoundTrip();
  // hooks
  const [conversationId, setConversationId] = useState('');
  const [flights, setFlights] = useState<{ [index: string]: any } | null>();
  // error handling hooks
  const [noResultsFoundError, setNoResultsFoundError] = useState(false);
  const [foundErrorTitle, setFoundErrorTitle] = useState('');
  const [foundErrorContent, setFoundErrorContent] = useState('');

  const airportLocations = async (location: string) => {
    try {
      const response: AxiosResponse = await axios.get(searchAirport(location), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      await refreshToken();
      return response;
    }
  };

  const hotelLocations = async (location: string) => {
    try {
      const response = await axios.request({
        method: 'get',
        maxBodyLength: Infinity,
        url: hotelLocation(location),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      await refreshToken();
      return error;
    }
  };

  const searchAvailableFlights = async (url: string, cabinType: string, searchType?: 'one-way' | 'round-trip' | 'multi-city') => {
    setFlights(null);

    try {
      const response: AxiosResponse = await axios.get(url);
      // return response;

      if (response.status === 200) {
        let foundFlights = [];

        // filter options
        setFilterKey(response.data.filterKey);

        const outbound = response.data.filters.outbound;
        setAirlineList(outbound.airlines);
        const priceRange: { min: number; max: number } = outbound.priceRange;
        setPriceRange({ start: priceRange.min, end: priceRange.max });

        // if (searchType === 'round-trip') {
        //   const inbound = response.data.filters.inbound;
        //   setReturnAirlineList(inbound.airlines);
        //   const priceRange = inbound.priceRange;
        //   setReturnPriceRange({ start: priceRange.min, end: priceRange.max });
        //   setSelectedReturnPriceRange({ start: priceRange.min, end: priceRange.max });
        // }
        // end of filter options

        const data = response.data.data.mystifly.Data;
        foundFlights = flightCabinType(cabinType.toLowerCase(), data);

        setConversationId(response.data.data.mystifly.ConversationId);
        setFlights(foundFlights);

        if (foundFlights.length === 0) {
          setNoResultsFoundError(true);
          setFoundErrorTitle('Uh-oh! No results found');
          setFoundErrorContent('Try different dates or destinations or contact our offline booking team for assistance and access to more exciting deals!');
        } else {
          setNoResultsFoundError(false);
          setFoundErrorTitle('');
          setFoundErrorContent('');
        }
      }
    } catch (error) {
      // const response = error as AxiosResponse;
      // return response;
      setNoResultsFoundError(true);
      setFoundErrorTitle('Something went wrong');
      setFoundErrorContent('An error has occurred and we’re working to fix the problem. Try refreshing the page or contact our offline booking team for assistance and access to more exciting deals!');
    }

  };

  return { airportLocations, hotelLocations, searchAvailableFlights, setFlights, conversationId, flights, noResultsFoundError, foundErrorTitle, foundErrorContent };
};
