import React, { Key } from 'react';
import { SelectedFlightInfo } from './SelectedFlightInfo';
import { AnimatedGalagoLoading } from '../../../components/AnimatedGalagoLoading';
import Skeleton from 'react-loading-skeleton';
import { RoundTripBundleFlightList } from './RoundTripBundleFlightList';
// import FlightFiltersOneWay from './FlightFilters';
import '../../../resources/css/search-filter.scss';
import SelectedFlightLabel from './SelectedFlightLabel';
import { ErrorGalagoPage } from '../../../components/ErrorGalagoPage';
import FlightFilters from './FlightFilters';

type RoundTripBundleFlightListType = {
  fromCityName: string;
  fromAirportCode: string;
  toCityName: string;
  toAirportCode: string;
  selectedFlight: { [index: string]: any } | null | undefined;
  setSelectedFlight: any;
  flights: { [index: string]: any } | null | undefined;
  viewFlightInfo?: () => void;
  searchParams: { [index: string]: any };
  // setSortingFilter?: (value: string) => void;
};

export const RoundTripBundle = ({
  fromCityName,
  fromAirportCode,
  toCityName,
  toAirportCode,
  selectedFlight,
  setSelectedFlight,
  flights,
  viewFlightInfo,
  // setSortingFilter,
  searchParams,
}: RoundTripBundleFlightListType) => {
  // const title = 'Uh-oh! No results found';
  // const content = "Unfortunately, we couldn't find any flights that fit your preferences. You may want to try modifying your search.";
  // console.log(flights);
  try {
    return (
      <>
        <div className="container mt-[4rem] mb-4  p-0">
          {/* arrival flights will show if the flight type is one way and round trip */}
          {/* {selectedFlight && (
            <div>
              <SelectedFlightLabel
                selectedFlight={selectedFlight}
                fromCityName={fromCityName}
                fromAirportCode={fromAirportCode}
                toCityName={toCityName}
                toAirportCode={toAirportCode}
                searchParams={searchParams}
              />
              <SelectedFlightInfo id="selected-departure-info" flightInfo={selectedFlight} changeFlight={() => setSelectedFlight(undefined)} />
            </div>
          )} */}

          <div className="container mb-[4rem] flex gap-4">
            {!selectedFlight && (
              <div className="search-container">
                <FlightFilters FlightType="round-trip" SearchParams={searchParams} />
              </div>
            )}

            <div className="flight-title flex flex-col w-100">
              <SelectedFlightLabel
                selectedFlight={selectedFlight}
                fromCityName={fromCityName}
                fromAirportCode={fromAirportCode}
                toCityName={toCityName}
                toAirportCode={toAirportCode}
                resultsCount={flights?.length}
                searchParams={searchParams}
              />
              {selectedFlight && (
                <>
                  <SelectedFlightInfo FlightType="round-trip" id="selected-departure-info" flightInfo={selectedFlight} changeFlight={() => setSelectedFlight(undefined)} />
                </>
              )}
              {selectedFlight ? null : (
                <div className="row flight-result-list">
                  {!flights || flights === null ? (
                    <>
                      <div className="mt-2">
                        <AnimatedGalagoLoading show={true} loadingText="Searching Flights. Please Wait..." />
                      </div>
                      <Skeleton count={10} height={100} />
                    </>
                  ) : (
                    // <Container className="flight-filter-result-container flex gap-4 w-100 my-[4rem]">
                    <>
                      <div className="w-100 mt-3">
                        <div className="flight-title">
                          <div className="flight-result-list ">
                            {flights?.map((info: { [index: string]: any }, key: Key) => {
                              return <RoundTripBundleFlightList info={info} key={info.FareSourceCode} />;
                            })}
                          </div>
                        </div>
                      </div>
                      <ErrorGalagoPage show={!flights?.length} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    return <></>;
  }
};
