import React, { useEffect } from 'react';
import { AnimatedGalagoLoading } from '../components/AnimatedGalagoLoading';
import { FacebookProvider } from '../context/FacebookContext';
import useFacebookLogin from '../hooks/useFacebookLogin';
import { SearchPage } from './search/SearchPage';
import Homepage from './home/Homepage';
import Footer from '../components/Footer';
import NavBarTransparent from '../components/NavBarTransparent';
import { isMobileTablet } from '../utils/pwa';

export const Index = () => {
  const { isLoading, minLoadingTime, isFetching, error, isError, fbLoginSuccess } = useFacebookLogin();

  // Function to check if the user is using a mobile or tablet
  const checkMobileTablet = () => {
    const m = isMobileTablet();
    if (m) {
      window.location.href = process.env.REACT_APP_PWA_URL as string;
    }
  };

  // Location service function
  const locationService = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log('Latitude:', position.coords.latitude);
        // console.log('Longitude:', position.coords.longitude);
      },
      (error) => {
        console.error('Error getting location:', error);
        if (error.code === error.PERMISSION_DENIED) {
          // console.log('Permission denied');
        }
      }
    );
  };

  useEffect(() => {
    checkMobileTablet();
    locationService();
  }, []);

  if ((isLoading && isFetching) || minLoadingTime) {
    return <AnimatedGalagoLoading show={true} loadingText="Logging in your Account..." />;
  }
  return (
    <>
      {/* <BodyBackground /> */}
      <FacebookProvider error={error} isError={isError} fbLoginSuccess={fbLoginSuccess}>
        <NavBarTransparent />
        <SearchPage />
        <Homepage />
        <Footer />
      </FacebookProvider>
    </>
  );
};
